import React from 'react';
import { Document, pdf } from '@react-pdf/renderer';
// components
import BasicInformation from './auditorBasicInfo';
import AuditorReportTemplate from './AuditorReportTemplate';
import { getFileFromS3Bucket } from '../../s3Utils';
import {
    getDocumentLinks,
    getManufactureDeviceImages,
    parseMSF,
    getReportTemplates,
    groupByNodeTypeAndMatchName,
    addTreatmentData,
    matchNames,
} from './auditorUtils.js';

const getAuditorReport = async (
    userInfo,
    projectInfo,
    mapInfo,
    compliantNodes,
    nonCompliantNodes,
    treatmentTrainsImageUrl,
    msf,
    nodesNotExistingInDB,
) => {
    let treatmentTypes = [];
    const treatments = parseMSF(msf);
    const reportHeaderIconUrl = await getFileFromS3Bucket(
        'company-icon',
        'totoev-logo-auditor.png',
    );
    let treatmentNodes = compliantNodes.filter((node) =>
        node.compliantTemplates.every((template) => template.table !== 'UrbanSourceNode'),
    );

    treatmentNodes.forEach((node) => {
        node.compliantTemplates.forEach((comp) => {
            treatmentTypes.push(comp.table);
        });
    });
    treatmentNodes = addTreatmentData(treatmentNodes, treatments);
    let groupedNodes = groupByNodeTypeAndMatchName(treatmentNodes);
    const transformedNodes = {};
    for (const key in groupedNodes) {
        if (groupedNodes.hasOwnProperty(key)) {
            const newKey = matchNames(key);
            transformedNodes[newKey] = groupedNodes[key];
        }
    }
    const reportTemplates = await getReportTemplates(groupedNodes);
    const keys = Object.keys(groupedNodes);
    const matchedKeysArray = keys.map((key) => matchNames(key));
    const manufactureDeviceImages = await getManufactureDeviceImages(matchedKeysArray);
    const manufacturerDocumentLinks = await getDocumentLinks(treatmentNodes);

    return (
        <Document>
            {/* Basic Information */}
            <BasicInformation
                address={{
                    suburb: mapInfo.suburb,
                    state: mapInfo.state,
                    postcide: mapInfo.postcode,
                    council: mapInfo.council,
                    address: mapInfo.address,
                    coordinate: {
                        lat: mapInfo.coordinate.lat,
                        lng: mapInfo.coordinate.lng,
                    },
                }}
                basicInfo={{
                    developmentType: projectInfo.developmentType,
                    permit: projectInfo.planningPermitNo,
                    projectName: 'N/A',
                    assessor: userInfo.assessorName,
                    email: userInfo.assessorEmail,
                }}
                reportHeaderUrl={reportHeaderIconUrl}
            />

            <AuditorReportTemplate
                compliantNodes={compliantNodes}
                nonCompliantNodes={nonCompliantNodes}
                reportHeaderUrl={reportHeaderIconUrl}
                treatmentTrainsImageUrl={treatmentTrainsImageUrl}
                manufactureDeviceImages={manufactureDeviceImages}
                manufacturerDocumentLinks={manufacturerDocumentLinks}
                treatmentTemplates={reportTemplates}
                treatmentData={groupedNodes}
                nodesNotExistingInDB={nodesNotExistingInDB}
            />
        </Document>
    );
};

// main function
export const generateAuditorReport = async (
    userInfo,
    projectInfo,
    compliantNodes,
    mapInfo,
    nonCompliantNodes,
    treatmentTrainsImageUrl,
    msf,
    nodesNotExistingInDB,
) => {
    try {
        const reportBlob = await pdf(
            await getAuditorReport(
                userInfo,
                projectInfo,
                mapInfo,
                compliantNodes,
                nonCompliantNodes,
                treatmentTrainsImageUrl,
                msf,
                nodesNotExistingInDB,
            ),
        ).toBlob();

        return reportBlob;
    } catch (err) {
        console.error(err);
    }
};
