// OverallResults.jsx

import React from 'react';
import ResultsTable from './ResultsTable';
import styles from './OverallResults.module.css';
import { useSelector } from 'react-redux';
import { selectWSUDResults } from '../../../../utils/redux/WSUD/wsudResultsSlice';

const OverallResults = ({ targetReductions, cityCouncil }) => {
    const overallResults = useSelector(selectWSUDResults);

    if (overallResults !== null) {
        return (
            <div className={styles.overallResults}>
                <p style={{ fontSize: 'larger' }}>Live Results</p>
                <div className={styles.resultsContainer}>
                    <div style={{ width: '40%' }}>
                        <ResultsTable
                            title="Overall STORM Ratings for LGA"
                            header={['TN (%)', 'TP (%)', 'TSS (%)', 'GP (%)']}
                            results={[
                                [
                                    overallResults.overallRatings?.tnRate,
                                    overallResults.overallRatings?.tpRate,
                                    overallResults.overallRatings?.tssRate,
                                    overallResults.overallRatings?.gpRate,
                                ],
                            ]}
                            greenOrRed={{
                                '00': parseFloat(overallResults.overallRatings?.tnRate) > 100,
                                '01': parseFloat(overallResults.overallRatings?.tpRate) > 100,
                                '02': parseFloat(overallResults.overallRatings?.tssRate) > 100,
                                '03': parseFloat(overallResults.overallRatings?.gpRate) > 100,
                            }}
                        />
                        {overallResults.overallRatings?.tpRate === 'NaN' && (
                            <h5 className={styles['alert']} style={{ color: 'red' }}>
                                Please enter all inputs
                            </h5>
                        )}
                        <h5>
                            Reaching a 100% STORM rating indicates that you have successfully met
                            the prescribed target reductions for this Authority. For instance,
                            achieving a STORM TN of 100% implies that you have fulfilled the target
                            pollutant reductions specified on the right.
                        </h5>
                    </div>

                    <div name="reductionTable">
                        <ResultsTable
                            title="Overall Reductions"
                            header={['', 'TN (%)', 'TP (%)', 'TSS (%)', 'GP (%)', 'Flow (%)']}
                            results={[
                                [
                                    'Achieved Reductions',
                                    overallResults.overallReductions?.tnReduction,
                                    overallResults.overallReductions?.tpReduction,
                                    overallResults.overallReductions?.tssReduction,
                                    overallResults.overallReductions?.gpReduction,
                                    overallResults.overallReductions?.flowReduction,
                                ],
                                [
                                    'LGA Target Reductions',
                                    targetReductions?.tn,
                                    targetReductions?.tp,
                                    targetReductions?.tss,
                                    targetReductions?.gp,
                                    'N/A',
                                ],
                                ['Greenstar Credit Achievement', 45, 65, 85, 90, 'N/A'],
                                ['Greenstar Exeptional Performance', 60, 70, 90, 95, 'N/A'],
                            ]}
                            greenOrRed={{
                                '01':
                                    parseFloat(overallResults.overallReductions?.tnReduction) >
                                    targetReductions.tn,
                                '02':
                                    parseFloat(overallResults.overallReductions?.tpReduction) >
                                    targetReductions.tp,
                                '03':
                                    parseFloat(overallResults.overallReductions?.tssReduction) >
                                    targetReductions.tss,
                                '04':
                                    parseFloat(overallResults.overallReductions?.gpReduction) >
                                    targetReductions.gp,
                            }}
                        />
                        <label htmlFor="reductionTable">
                            Required Target Reductions For {cityCouncil}
                        </label>
                    </div>
                </div>
            </div>
        );
    } else return <></>;
};

export default OverallResults;
