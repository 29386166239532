// modules
import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
// css
import './styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// utils
import {
    fetchAllSideBarAdvertisement,
    fetchAllSidebarAdvertisementLinks,
} from './utils/advertisementUtils';
// components
import AdsPanel from './Components/ads-panel/AdsPanel';
import TheHeader from './Components/header/TheHeader';
import TheFooter from './Components/footer/TheFooter';
// tools
import {
    checkIsCompanyAdminAccount,
    checkIsCompanySubAccount,
    getProjectByProjId,
    verifyJwt,
    getSavedModel,
} from './utils/api/lambda';
import NewFeaturesModal from './Components/NewFeaturesModal';
import { useDispatch, useSelector } from 'react-redux';
import { setMapInfo } from './utils/redux/mapInfoSlice';
import { selectUserInfo, setUserInfo } from './utils/redux/userInfoSlice';
import Calculator from './Components/Calculator/Calculator';
import { getImageUrlFromS3 } from './utils/api/s3';
import axios from 'axios';
import { setProjectInfo } from './utils/redux/projectInfoSlice';

function App() {
    const navigate = useNavigate();
    const { search, pathname } = useLocation();
    const userInfo = useSelector(selectUserInfo);
    const dispatch = useDispatch();

    const [ads, setAds] = useState([{}]);
    const [loading, setLoading] = useState(true);
    const loadedModel = useRef(null);
    const tool = pathname.replace(/\//g, '');

    const fetchHeaderIconImage = async (email) => {
        const isAdminAccount = await checkIsCompanyAdminAccount(email);
        const isCompanySubAccount = await checkIsCompanySubAccount(email);
        let fileName;
        if (isAdminAccount || isCompanySubAccount) {
            fileName = `${email.split('@')[1].replace(/\./g, '')}.png`;
        } else {
            fileName = `${email}.png`;
        }
        try {
            const url = await getImageUrlFromS3('company-icon', fileName);
            await axios.get(url);
            return url;
        } catch (error) {
            return await getImageUrlFromS3('company-icon', 'totoev-logo.png');
        }
    };

    useEffect(() => {
        fetchAllSideBarAdvertisement().then((adsImageFromS3) => {
            fetchAllSidebarAdvertisementLinks().then((adsLinkFromDB) => {
                const result = adsImageFromS3[0].map((img) => {
                    const matchedObj = adsLinkFromDB[0].find(
                        (link) => link.advertisement_name === img.name,
                    );
                    return { ...img, ...matchedObj };
                });
                setAds(result);
            });
        });
    }, []);

    useEffect(() => {
        const extractUserDetails = async () => {
            setLoading(true);

            const params = new URLSearchParams(search);
            const token = params.get('token');
            const project = params.get('project');
            const model_id = params.get('model_id');
            const refreshToken = params.get('refresh');

            const isValidTokenResponse = (res) => {
                return typeof res === 'object' && res !== null &&
                    'user_id' in res && 'name' in res &&
                    'email' in res && 'client_id' in res;
            };

            const handleTokenVerification = async (token) => {
                try {
                    const res = await verifyJwt(token, refreshToken);
                    if (isValidTokenResponse(res)) {
                        const { name, email, user_id } = res;
                        const reportHeaderIcon = await fetchHeaderIconImage(email.replace(/"/g, ''));
                        
                        dispatch(setUserInfo({
                            assessorName: name.replace(/"/g, ''),
                            assessorEmail: email.replace(/"/g, ''),
                            userID: user_id.replace(/"/g, ''),
                            reportHeaderIcon,
                        }));

                        navigate(`/${tool}?${model_id ? `model_id=${model_id}` : project ? `project=${project}` : ''}`);
                    } else {
                        throw new Error('Invalid keys in token!');
                    }
                } catch (error) {
                    console.error('Error verifying token:', error);
                    handleSignInError(model_id, project);
                }
            };

            if (token) {
                handleTokenVerification(token);
            } else {
                handleSignInError(model_id, project);
            }
        };

        const handleSignInError = (model_id, project) => {
            try {
                const domain = window.location.href.split('?')[0];
                const appendix = model_id
                    ? `model_id=${model_id}&&redirect=${domain}`
                    : project
                      ? `project=${project}&&redirect=${domain}`
                      : '';
                if (!appendix) {
                    alert('Token Invalid or Missing, Redirecting to user portal...');
                }
                window.open(
                    `https://www.user-portal.stormupdated.com.au/${appendix ? `?appendixToken=${btoa(appendix)}` : ''}`,
                    '_self',
                );
            } catch (error) {
                window.open(`https://www.user-portal.stormupdated.com.au/`, '_self');
            }
        };
        extractUserDetails();
    }, []);

    useEffect(() => {
        const getProjectDetails = async () => {
            const params = new URLSearchParams(search);
            let project = params.get('project');
            const model_id = params.get('model_id');
            if (userInfo.assessorEmail) {
                try {
                    const getAddressDetails = async (projId) => {
                        const addressDetails = await getProjectByProjId(projId);
                        return {
                            projectID: projId,
                            state: addressDetails[0].state,
                            council: addressDetails[0].city_council,
                            rainfallStation: addressDetails[0].rainfall_station,
                            suburb: addressDetails[0].suburb,
                            postcode: addressDetails[0].postcode,
                            address: addressDetails[0].address,
                            coordinate: {
                                lat: addressDetails[0].lat,
                                lng: addressDetails[0].lng,
                            },
                        };
                    };

                    if (model_id) {
                        const models = await getSavedModel(tool, model_id);
                        if (models.length === 0) {
                            return;
                        }
                        const model = models[0];
                        dispatch(
                            setMapInfo({
                                projectID: model.project_id,
                                state: model.state,
                                council: model.city_council,
                                rainfallStation: model.rainfall_station,
                                suburb: model.suburb,
                                postcode: model.postcode,
                                address: model.address,
                                coordinate: {
                                    lat: model.lat,
                                    lng: model.lng,
                                },
                            }),
                        );
                        dispatch(
                            setProjectInfo({
                                developmentType: model.development_type,
                                planningPermitNo: model.permit_number,
                            }),
                        );
                        loadedModel.current = model;
                    } else if (project) {
                        const addressDetails = await getAddressDetails(project);
                        dispatch(setMapInfo(addressDetails));
                    } else {
                        throw Error('project id not found');
                    }
                    setLoading(false);
                } catch (error) {
                    console.error('Error verifying token:', error);
                    handleSignInError(model_id);
                }
            }
        };

        const handleSignInError = (model_id) => {
            window.open(`https://www.user-portal.stormupdated.com.au/`, '_self');
        };

        getProjectDetails();
    }, [userInfo]);

    return (
        <>
            {!loading && (
                <>
                    {/* Show pop-up window */}
                    <NewFeaturesModal tool={tool} />
                    {/* Main Page */}
                    <div className="app-container d-flex flex-column">
                        <div
                            className="flex-1 d-flex justify-content-center align-items-center"
                            style={{
                                backgroundColor: '#040f19',
                                padding: '10px',
                                fontFamily: `"DaggerSquare", "Courier New", Courier, monospace`,
                                fontSize: '1.75rem',
                            }}
                        >
                            <TheHeader />
                        </div>
                        <div
                            className="flex-5 d-flex flex-row justify-content-center align-items-start"
                            style={{ paddingBlock: 20 }}
                        >
                            <div className="flex-1">
                                <AdsPanel whichSide="Left" ads={ads} />
                            </div>
                            <div className="flex-4 ">
                                <Calculator tool={tool} loadedModel={loadedModel.current} />
                            </div>
                            <div className="flex-1">
                                <AdsPanel whichSide="Right" ads={ads} />
                            </div>
                        </div>
                        <div
                            className="flex-1"
                            style={{
                                color: '#f0f9ff',
                                backgroundColor: '#040f19',
                                fontFamily: `"DaggerSquare", "Courier New", Courier, monospace`,
                                fontSize: '1.75rem',
                            }}
                        >
                            <TheFooter />
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default App;
