import React from "react";
import LiveResultRow from "./LiveStormResult/LiveResultRow";
import AreaFormInputRow from "./AreaFormInputRow";

const AreaFormRow = ({ area, index, areaTypes }) => {
    return (
        <div key={`input-and-result-row-${index}`}>
            <AreaFormInputRow area={area} index={index} areaTypes={areaTypes} />
            <LiveResultRow
                result={area.result}
                resultUpdateRequired={area.resultUpdateRequired}
            />
        </div>
    );
};

export default AreaFormRow;
