import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
// utils
import {
    sails_api,
    getSecondaryLinkProps,
    treatmentNameDict,
    nodeTypeDict,
} from './globalConstant';
import { countClick, getFileFromS3Bucket, uploadFileToS3Bucket } from './s3Utils';
import { getNodeInfoFromMsf } from './msfReader';
import { getTreatmentTrainsImage } from './downloadMethods';
import { sendSuReport } from './emailer';
import { generateSuCalculatorReport } from './react-pdf/su-calculator-report/suCalculatorReportGenerator';
import { calculateTotalArea } from './wsudAreaUtils';
import { generateSuLiteReport } from './react-pdf/su-lite-report/suLiteReportGenerator';
import { generateIdmReport } from './react-pdf/osd-idm-report/idmReportGenerator';
import { getOsdImageLinkAndDocument } from './getOsdData';
import { generateSwinburneReport } from './react-pdf/swinburne-report/SwinburneReportGenerator';
import { generateBoydsReport } from './react-pdf/osd-boyds-report/BoydsReportGenerator';
import { generateRationalReport } from './react-pdf/osd-rational-report/RationalReportGenerator';
import { getTreatmentInfoTemplate } from './treatmentInfoTemplate';
import { saveModelToDB } from './cmsUtils';
import { generateModifiedRationalReport } from './react-pdf/osd-modified-rational-report/ModifiedRationalReportGenerator';
import { saveNewSUModel } from './api/lambda';
import { generateRWTDemandReport } from './react-pdf/rwt-demand-report/RWTDemandReportGenerator';

const constructAddress = (addressMain, suburb, state, zipcode) => {
    return (
        (addressMain ? addressMain : 'No address') +
        ', ' +
        (suburb ? suburb : 'No suburb') +
        ', ' +
        (state ? state : 'No state') +
        ', ' +
        (zipcode ? zipcode : 'No zipcode')
    );
};

const initiateReportDownload = (blob, filename) => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    URL.revokeObjectURL(link.href);
};

const mapToServerVariables = (areas) => {
    const mappedImperviousNodes = areas.impervious.map((area) => {
        let mappedArea = {
            impAreaName: area.areaName,
            impAreaType: area.areaType,
            impAreaSize: area.areaSize,
            impAreaTreatType: area.treatmentType,
            impAreaTreatSize: area.treatmentType === 'None' ? '' : area.treatmentSize,
            impAreaBrooms: area.treatmentType === 'None' ? '' : area.numberOfOccupants,
            tn: area.result?.stormRatingPercTN?.toFixed(1),
            tp: area.result?.stormRatingPercTP?.toFixed(1),
            tss: area.result?.stormRatingPercTSS?.toFixed(1),
            gp: area.result?.stormRatingPercGP?.toFixed(1),
            fr: area.result?.reductionPercFlow?.toFixed(1),
            wsr: area.result?.waterSupplyReliability?.toFixed(1) ?? 'N/A',
            results: area.result,
        };

        return mappedArea;
    });

    const mappedPerviousNodes = areas.pervious.map((area) => {
        let mappedArea = {
            pAreaName: area.areaName,
            pAreaType: area.areaType,
            pAreaSize: area.areaSize,
            pAreaTreatType: area.treatmentType,
            pAreaTreatSize: area.treatmentType === 'None' ? '' : area.treatmentSize,
            pAreaBrooms: area.treatmentType === 'None' ? '' : area.numberOfOccupants,
            tn: area.result?.stormRatingPercTN?.toFixed(1),
            tp: area.result?.stormRatingPercTP?.toFixed(1),
            tss: area.result?.stormRatingPercTSS?.toFixed(1),
            gp: area.result?.stormRatingPercGP?.toFixed(1),
            fr: area.result?.reductionPercFlow?.toFixed(1),
            wsr: area.result?.waterSupplyReliability?.toFixed(1) ?? 'N/A',
            results: area.result,
        };
        return mappedArea;
    });
    return [mappedImperviousNodes, mappedPerviousNodes];
};

/* WSUD Report Email Function */
export const emailWsudReport = async (
    userInfo,
    mapInfo,
    projectInfo,
    areas,
    siteDetails,
    wsudResults,
    targetReductions,
    catchmentType,
    reportNoteSection,
) => {
    // the following two variables map refactored code to naming conventions
    // used in the backend
    const [mappedImperviousNodes, mappedPerviousNodes] = mapToServerVariables(areas);

    const perviousArea = calculateTotalArea(areas['pervious']);
    const imperviousArea = calculateTotalArea(areas['impervious']);

    const mappedPayload = {
        assessorName: userInfo.assessorName,
        assessorEmail: userInfo.assessorEmail,
        developmentType: projectInfo.developmentType,
        catchmentType: catchmentType,
        rainfallStation: mapInfo.rainfallStation,
        cityCouncil: mapInfo.council,
        addressMain: mapInfo.address,
        addressSub: '',
        suburb: mapInfo.suburb,
        zipcode: mapInfo.postcode,
        state: mapInfo.state,
        siteArea: imperviousArea + perviousArea,
        rainwaterDemand: siteDetails.rainwaterDemand,
        impervious: mappedImperviousNodes.length > 0,
        imperviousNodes: mappedImperviousNodes,
        pervious: mappedPerviousNodes.length > 0,
        perviousNodes: mappedPerviousNodes,
        wqMethod: siteDetails.eolResult !== null ? 'EOLStandard' : 'Standard',
        hasWQ: true,
        hasOsd: false,
        eolTreatment: siteDetails.treatmentDevice,
        eolTreatmentSize: siteDetails.deviceInput,
    };

    let completeImperviousNodes = [];
    let completePerviousNodes = [];

    // get MSF data
    const msfData = await sails_api
        .post('/getMSF', mappedPayload)
        .then((res) => {
            return res.data;
        })
        .catch(() => {
            alert('msf not available');
        });

    const msfDataPassable = msfData.replace(/&/gi, 'and');
    // get the report table information from MSF
    const result = getNodeInfoFromMsf(mappedImperviousNodes, mappedPerviousNodes, msfDataPassable);
    completeImperviousNodes = result.completeImperviousNodes;
    completePerviousNodes = result.completePerviousNodes;

    // get treatment trains image
    const treatmentTrainsImageUrl = await getTreatmentTrainsImage(msfData);

    return generateSuCalculatorReport(
        userInfo,
        projectInfo,
        mapInfo,
        mappedPayload.wqMethod,
        true,
        mappedPayload.impervious,
        mappedPayload.pervious,
        { ...wsudResults, eolResults: siteDetails.eolResult },
        targetReductions,
        completeImperviousNodes,
        completePerviousNodes,
        [],
        [],
        siteDetails.treatmentDevice,
        siteDetails.deviceInput,
        userInfo.reportHeaderIcon,
        treatmentTrainsImageUrl,
        reportNoteSection,
    ).then(async (reportBlob) => {
        //store report in s3

        initiateReportDownload(reportBlob, `${mapInfo.address} - Report.pdf`);

        saveNewSUModel(
            mapInfo.projectID,
            siteDetails,
            areas['impervious'],
            areas['pervious'],
            'Default Save',
            projectInfo,
            true,
            userInfo.assessorEmail,
        ).then((response) => {
            uploadFileToS3Bucket(
                `calculator/${response[0].model_id}.pdf`,
                'jco-user-reports',
                reportBlob,
            );
        });

        return sendSuReport(
            reportBlob,
            userInfo.assessorName,
            userInfo.assessorEmail,
            mapInfo.address + ' ' + mapInfo.state + ' ' + mapInfo.zipcode,
            'Su Calculator Report',
        );
    });
};

/* Lite Report Email Function */
export const emailLiteWsudReport = async (
    userInfo,
    mapInfo,
    projectInfo,
    areas,
    siteDetails,
    wsudResults,
    targetReductions,
) => {
    const address = `${mapInfo.address}, ${mapInfo.suburb}, ${mapInfo.state}, ${mapInfo.postcode}`;

    const downloadRecordInfo = {
        name: userInfo.assessorName,
        email: userInfo.assessorEmail,
        address: address,
        downloadButton: 'report',
        source: window.location.href,
        sessionID: '',
    };

    // downloadRecordInfo.downloadButton = what;
    await sails_api.post('/RecordDownload', downloadRecordInfo);

    const imperviousNodes = areas.impervious.map((item) => ({
        TreatType: item.treatmentType,
        TreatSize: item.treatmentSize,
    }));
    const perviousNodes = areas.pervious.map((item) => ({
        TreatType: item.treatmentType,
        TreatSize: item.treatmentSize,
    }));

    const treatmentDevices = [...imperviousNodes, ...perviousNodes];

    countClick(userInfo.assessorEmail, 17);

    // the following three variables map refactored code to naming conventions
    // used in the backend
    const [mappedImperviousNodes, mappedPerviousNodes] = mapToServerVariables(areas);

    const perviousArea = calculateTotalArea(areas['pervious']);
    const imperviousArea = calculateTotalArea(areas['impervious']);

    const mappedPayload = {
        assessorName: userInfo.assessorName,
        assessorEmail: userInfo.assessorEmail,
        developmentType: projectInfo.developmentType,
        catchmentType: projectInfo.catchmentType,
        rainfallStation: mapInfo.rainfallStation,
        cityCouncil: mapInfo.council,
        addressMain: mapInfo.address,
        addressSub: '',
        suburb: mapInfo.suburb,
        zipcode: mapInfo.postcode,
        state: mapInfo.state,
        siteArea: imperviousArea + perviousArea,
        rainwaterDemand: siteDetails.rainwaterDemand,
        impervious: mappedImperviousNodes.length > 0,
        imperviousNodes: mappedImperviousNodes,
        pervious: mappedPerviousNodes.length > 0,
        perviousNodes: mappedPerviousNodes,
        wqMethod: siteDetails.eolResult !== null ? 'EOLStandard' : 'Standard',
        hasWQ: true,
        hasOSd: false,
        eolTreatment: siteDetails.treatmentDevice,
        eolTreatmentSize: siteDetails.deviceInput,
        overallResults: wsudResults,
        latitude: mapInfo.coordinate.lat,
        longitude: mapInfo.coordinate.lng,
    };

    let reportNumber = 0;
    sails_api
        .post('/saveReport', {
            metadata: {
                ...mappedPayload,
                overallResults: wsudResults,
                ip: '',
                sessionID: '',
                source: '',
                reportId: reportNumber,
                totalImpArea: imperviousArea,
                totalPArea: perviousArea,
            },
            overallRatings: wsudResults.overallRatings,
            type: 'simple-report',
        })
        .then((res) => {
            reportNumber = res.data.reportId;
        })
        .catch((err) => console.log(err));

    let modelId = '0';
    sails_api
        .post('/saveDataToNewDB', {
            metadata: mappedPayload,
            origin: 'su-lite-report',
            treatmentDevices: JSON.stringify(treatmentDevices),
        })
        .then((res) => {
            modelId = res.data.id;
        })
        .catch((err) => console.log(err));

    return generateSuLiteReport(
        userInfo,
        projectInfo,
        mapInfo,
        mappedImperviousNodes,
        siteDetails.treatmentDevice,
        siteDetails.deviceInput,
        { ...wsudResults, eolResults: siteDetails.eolResult },
        perviousArea + imperviousArea,
        targetReductions,
        siteDetails.rainwaterDemand,
    ).then((reportBlob) => {
        initiateReportDownload(reportBlob, `${mapInfo.address} - Lite Report.pdf`);

        saveNewSUModel(
            mapInfo.projectID,
            siteDetails,
            areas['impervious'],
            areas['pervious'],
            'Default Save',
            projectInfo,
            true,
            userInfo.assessorEmail,
        ).then((reponse) => {
            uploadFileToS3Bucket(
                `calculator/${reponse[0].model_id}.pdf`,
                'jco-user-reports',
                reportBlob,
            );
        });

        return sendSuReport(
            reportBlob,
            userInfo.assessorName,
            userInfo.assessorEmail,
            mapInfo.address + ' ' + mapInfo.state + ' ' + mapInfo.zipcode,
            'Su Lite',
        );
    });
};

/* MSF File Email Function */
export const emailMSF = async (userInfo, mapInfo, projectInfo, areas, siteDetails) => {
    const address = `${mapInfo.address}, ${mapInfo.suburb}, ${mapInfo.state}, ${mapInfo.postcode}`;

    const downloadRecordInfo = {
        name: userInfo.assessorName,
        email: userInfo.assessorEmail,
        address: address,
        downloadButton: 'report',
        source: window.location.href,
        sessionID: '',
    };

    await sails_api.post('/RecordDownload', downloadRecordInfo);

    const imperviousNodes = areas.impervious.map((item) => ({
        TreatType: item.treatmentType,
        TreatSize: item.treatmentSize,
    }));
    const perviousNodes = areas.pervious.map((item) => ({
        TreatType: item.treatmentType,
        TreatSize: item.treatmentSize,
    }));

    const treatmentDevices = [...imperviousNodes, ...perviousNodes];

    countClick(userInfo.assessorEmail, 18);

    // the following three variables map refactored code to naming conventions
    // used in the backend
    const [mappedImperviousNodes, mappedPerviousNodes] = mapToServerVariables(areas);

    const perviousArea = calculateTotalArea(areas['pervious']);
    const imperviousArea = calculateTotalArea(areas['impervious']);
    const wqMethod = siteDetails.eolResult !== null ? 'EOLStandard' : 'Standard';

    const mappedPayload = {
        assessorName: userInfo.assessorName,
        assessorEmail: userInfo.assessorEmail,
        developmentType: projectInfo.developmentType,
        catchmentType: siteDetails.catchmentType,
        rainfallStation: mapInfo.rainfallStation,
        cityCouncil: mapInfo.council,
        addressMain: mapInfo.address,
        addressSub: '',
        suburb: mapInfo.suburb,
        zipcode: mapInfo.postcode,
        state: mapInfo.state,
        siteArea: imperviousArea + perviousArea,
        rainwaterDemand: siteDetails.rainwaterDemand,
        impervious: mappedImperviousNodes.length > 0,
        imperviousNodes: mappedImperviousNodes,
        pervious: mappedPerviousNodes.length > 0,
        perviousNodes: mappedPerviousNodes,
        wqMethod,
        hasWQ: true,
        hasOsd: false,
        eolTreatment: siteDetails.treatmentDevice,
        eolTreatmentSize: siteDetails.deviceInput,
        emailMSFToggle: true,
    };

    //save model to new cms jco_usage
    let modelId = '0';
    sails_api
        .post('/saveDataToNewDB', {
            metadata: {
                ...mappedPayload,
                latitude: mapInfo.coordinate.lat,
                longitude: mapInfo.coordinate.lng,
            },
            origin: 'su-report',
            treatmentDevices: JSON.stringify(treatmentDevices),
        })
        .then((res) => {
            modelId = res.data.id;
        })
        .catch((err) => console.log(err));

    // get MSF data
    await sails_api
        .post('/getMSF', mappedPayload)
        .then((res) => {
            //upload msf to s3 bucket
            const msfFile = new Blob([res.data], {
                type: 'text/plain',
            });
            initiateReportDownload(msfFile, `${mapInfo.address}.msf`);

            const formData = new FormData();
            formData.append('msf', msfFile, `${modelId}.msf`);
            formData.append(
                'params',
                JSON.stringify({
                    bucket: 'jco-user-model-html',
                    key: `msf/${modelId}.msf`,
                }),
            );
            formData.append('endpoint', 'uploadToS3');
            axios.post('https://xgr5xf78jh.execute-api.ap-southeast-2.amazonaws.com/s3', formData);
        })
        .catch(() => {
            alert('msf not available');
        });
};

/* OSD Report Email Function */
export const emailOSDReport = async (
    userInfo,
    mapInfo,
    projectInfo,
    osdMethod,
    osdCompany,
    osdMaterial,
    storageType,
    osdResultData,
    templateData,
    reportHeaderIconUrl,
    saveModelFunction,
) => {
    const address = `${mapInfo.address}, ${mapInfo.suburb}, ${mapInfo.state}, ${mapInfo.postcode}`;

    const reportPayload = {
        ...userInfo,
        ...projectInfo,
        ...mapInfo,
        latitude: mapInfo.coordinate.lat,
        longitude: mapInfo.coordinate.lng,
        osdResult: { ...osdResultData },
        osd: templateData,
        storageType: storageType,
        OSDCompany: osdCompany,
        OSDMaterial: osdMaterial,
        reportHeaderIconUrl: reportHeaderIconUrl,
    };

    let manufactureImageLinks = '';
    let manufactureDocuments = '';

    await getOsdImageLinkAndDocument(osdCompany).then((res) => {
        manufactureImageLinks = res.manufactureImageLinks;
        manufactureDocuments = res.manufactureDocuments;
    });

    if (osdMethod === 'Swinburne') {
        return generateSwinburneReport(
            {
                ...reportPayload,
                osdResult:
                    reportPayload.osdResult.data[storageType.toLowerCase().replace(/\s+/g, '_')],
            },
            manufactureImageLinks,
            manufactureDocuments,
        ).then((res) => {
            initiateReportDownload(res, `${mapInfo.address} - Swinburne.pdf`);
            saveModelFunction(res);
            return sendSuReport(
                res,
                userInfo.assessorName,
                userInfo.assessorEmail,
                address,
                'OSDs FOR VIPs',
            );
        });
    } else if (osdMethod === 'Rational') {
        return generateRationalReport(
            reportPayload,
            manufactureImageLinks,
            manufactureDocuments,
        ).then((res) => {
            initiateReportDownload(
                res,
                `${mapInfo.address} - ${templateData.method.split(' ')[0]}.pdf`,
            );
            saveModelFunction(res);
            return sendSuReport(
                res,
                userInfo.assessorName,
                userInfo.assessorEmail,
                address,
                'OSDs FOR VIPs',
            );
        });
    } else if (osdMethod === 'Boyds') {
        return generateBoydsReport(reportPayload, manufactureImageLinks, manufactureDocuments).then(
            (res) => {
                initiateReportDownload(
                    res,
                    `${mapInfo.address} - ${templateData.method.split(' ')[0]}.pdf`,
                );
                saveModelFunction(res);
                return sendSuReport(
                    res,
                    userInfo.assessorName,
                    userInfo.assessorEmail,
                    address,
                    'OSDs FOR VIPs',
                );
            },
        );
    } else if (osdMethod === 'Modified Rational') {
        return generateModifiedRationalReport(
            reportPayload,
            manufactureImageLinks,
            manufactureDocuments,
        ).then((res) => {
            initiateReportDownload(res, `${mapInfo.address} - Modified Rational.pdf`);
            saveModelFunction(res);
            return sendSuReport(
                res,
                userInfo.assessorName,
                userInfo.assessorEmail,
                address,
                'OSDs FOR VIPs',
            );
        });
    } else {
        return generateIdmReport(
            userInfo,
            projectInfo,
            mapInfo,
            osdResultData,
            templateData,
            storageType,
            osdCompany,
            osdMaterial,
            manufactureImageLinks,
            manufactureDocuments,
            reportHeaderIconUrl,
        ).then((res) => {
            initiateReportDownload(res, `${mapInfo.address} - IDM.pdf`);
            saveModelFunction(res);
            return sendSuReport(
                res,
                userInfo.assessorName,
                userInfo.assessorEmail,
                address,
                'OSDs FOR VIPs',
            );
        });
    }
};

/* MLB Download Function */
export const handleDownloadMLB = async (rainfallStation) => {
    const mlbDownloadName = rainfallStation + '.mlb';
    const mlburl = await getFileFromS3Bucket('mlbfiles', mlbDownloadName);

    if (mlburl) {
        const element = document.createElement('a');
        element.setAttribute('href', mlburl);
        element.setAttribute('download', mlbDownloadName);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);

        // await sails_api.post('/RecordDownload', downloadRecordInfo);
    } else {
        alert('Unable to find the MLB. Please try again!');
        console.error(`The MLB name was not found, MLB name: ${mlbDownloadName}`);
    }
};

/* Go To JCO Function */
export const handleGoToJCO = async (
    userInfo,
    projectInfo,
    mapInfo,
    areas,
    siteDetails,
    catchmentType,
) => {
    const imperviousNodes = areas.impervious.map((area) => {
        let mappedArea = {
            impAreaName: area.areaName,
            impAreaType: area.areaType,
            impAreaSize: area.areaSize,
            impAreaTreatType: area.treatmentType,
            impAreaTreatSize: area.treatmentSize,
            impAreaBrooms: area.numberOfOccupants,
            tn: area.result?.stormRatingPercTN?.toFixed(1),
            tp: area.result?.stormRatingPercTP?.toFixed(1),
            tss: area.result?.stormRatingPercTSS?.toFixed(1),
            gp: area.result?.stormRatingPercGP?.toFixed(1),
            fr: area.result?.reductionPercFlow?.toFixed(1),
            wsr: area.result?.waterSupplyReliability?.toFixed(1) ?? 'N/A',
            results: area.result,
        };

        return mappedArea;
    });

    const perviousNodes = areas.pervious.map((area) => {
        let mappedArea = {
            pAreaName: area.areaName,
            pAreaType: area.areaType,
            pAreaSize: area.areaSize,
            pAreaTreatType: area.treatmentType,
            pAreaTreatSize: area.treatmentSize,
            pAreaBrooms: area.numberOfOccupants,
            tn: area.result?.stormRatingPercTN?.toFixed(1),
            tp: area.result?.stormRatingPercTP?.toFixed(1),
            tss: area.result?.stormRatingPercTSS?.toFixed(1),
            gp: area.result?.stormRatingPercGP?.toFixed(1),
            fr: area.result?.reductionPercFlow?.toFixed(1),
            wsr: area.result?.waterSupplyReliability?.toFixed(1) ?? 'N/A',
            results: area.result,
        };
        return mappedArea;
    });

    /* Basic Information */
    const basicInfo = {
        assessor: userInfo.assessorName,
        email: userInfo.assessorEmail,
        'development-type': projectInfo.developmentType,
        permit: projectInfo.planningPermitNo,
        'project-name': '',
        'catchment-type': catchmentType,
        'city-council': mapInfo.council,
        address: mapInfo.address,
        suburb: mapInfo.suburb,
        postcode: mapInfo.postcode,
        state: mapInfo.state,
    };

    /* Node Info */
    let nodeList;
    let currentNodeId;
    let currentLinkId = 0;
    const links = [];
    // default node
    nodeList = [
        {
            id: '0',
            label: 'LPOD',
            x: 400,
            y: 480,
            isDragging: false,
            img: '',
            showingNote: false,
            linkFlowTemplate: [],
            nodeInfo: {
                'Node Type': 'ReceivingNode',
                'Node Name': 'LPOD',
                'Custom Name': '',
                Notes: '',
            },
        },
    ];
    currentNodeId = 1;
    // add EOL device node
    const eolDevice = siteDetails.treatmentDevice;
    const eolExist = eolDevice !== '' && siteDetails.deviceInput !== '';
    const eolDeviceSize = eolExist ? siteDetails.deviceInput : 0;
    if (eolExist) {
        const nodeName = treatmentNameDict[eolDevice] ? treatmentNameDict[eolDevice] : eolDevice;
        nodeList.push({
            id: '1',
            x: 400,
            y: 350,
            isDragging: false,
            img: '',
            showingNote: false,
            linkFlowTemplate: await getSecondaryLinkProps(nodeName),
            label: `-${eolDeviceSize}L/s-100mm fall-${nodeName}`,
            nodeInfo: {
                'Node Type': 'GPTNode',
                'Node Name': nodeName,
                Notes: '',
                'Custom Name': '',
                'Node Label': `-${eolDeviceSize}L/s-100mm fall-${nodeName}`,
                'High Flow By-pass (L/s)': parseFloat(eolDeviceSize),
            },
        });
        currentNodeId = 2;
        links.push({
            srcNodeID: 1,
            targetNodeID: 0,
            linkID: '0',
            strokeColor: 'black',
            isSecondary: false,
            selected: false,
            linkFlow: await getSecondaryLinkProps(nodeName),
        });
        currentLinkId += 1;
    }

    let xValue = 20;
    let yValue = 0;
    let modelCatchment = '';
    if (mapInfo.state.toUpperCase().includes('QLD')) {
        if (projectInfo.developmentType.includes('Commercial')) modelCatchment = 'Commercial ';
        else if (projectInfo.developmentType.includes('Industrial')) modelCatchment = 'Industrial ';
        else if (projectInfo.developmentType.includes('Residential'))
            modelCatchment = 'Residential ';
    }
    // Impervious Nodes
    for (const index in imperviousNodes) {
        const buf =
            mapInfo.state.toUpperCase().includes('QLD') &&
            imperviousNodes[index].impAreaType.toUpperCase().includes('GROUND')
                ? 'landscape'
                : imperviousNodes[index].impAreaType;
        const areaType = modelCatchment + buf;
        const areaName = imperviousNodes[index].impAreaName;
        const areaSize = imperviousNodes[index].impAreaSize;
        /** add Source Node **/
        if (areaSize !== '') {
            nodeList.push({
                id: currentNodeId.toString(),
                x: xValue,
                y: yValue,
                isDragging: false,
                img: '',
                label: `${areaName}-${areaSize}sqm-100%-${areaType}`,
                showingNote: false,
                nodeInfo: {
                    'Node Type': 'UrbanSourceNode',
                    'Node Name': areaType,
                    Notes: '',
                    'Custom Name': areaName,
                    'Node Label': `${areaName}-${areaSize}sqm-100%-${areaType}`,
                    'Zoning Surface Type': areaType,
                    'Total Area (m2)': areaSize,
                    'Imperviousness (%)': 100,
                    'Perviousness (%)': 0,
                },
                linkFlowTemplate: await getSecondaryLinkProps(
                    imperviousNodes[index].impAreaTreatType,
                ),
            });
            // increase the current ID
            currentNodeId += 1;
            /** add Treatment Node **/
            // get Node Name and Node Type
            let nodeName = imperviousNodes[index].impAreaTreatType;
            if (nodeName === 'None') {
                links.push({
                    srcNodeID: +currentNodeId - 1,
                    targetNodeID: eolExist ? 1 : 0,
                    linkID: currentLinkId.toString(),
                    strokeColor: 'black',
                    isSecondary: false,
                    selected: false,
                    linkFlow: await getSecondaryLinkProps(areaName),
                });
                currentLinkId += 1;
                if (yValue < 600) {
                    yValue += 120;
                } else {
                    if (xValue < 700) {
                        yValue = 0;
                        xValue += 700;
                    } else {
                        yValue = 0;
                        xValue += 260;
                    }
                }
            } else {
                let nodeType = 'GPTNode';
                if (nodeTypeDict[nodeName]) nodeType = nodeTypeDict[nodeName];
                if (treatmentNameDict[nodeName]) nodeName = treatmentNameDict[nodeName];
                let treatmentSize = imperviousNodes[index]['impAreaTreatSize'];
                let rainwaterDemand = siteDetails.rainwaterDemand;
                let impAreaBrooms = imperviousNodes[index]['impAreaBrooms'];
                const info = getTreatmentInfoTemplate(
                    imperviousNodes[index].impAreaTreatType,
                    nodeType,
                    nodeName,
                    treatmentSize,
                    rainwaterDemand,
                    impAreaBrooms,
                );
                nodeList.push({
                    id: currentNodeId.toString(),
                    x: xValue >= 700 ? xValue - 120 : xValue + 120,
                    y: yValue,
                    isDragging: false,
                    img: '',
                    showingNote: false,
                    linkFlowTemplate: await getSecondaryLinkProps(nodeName),
                    hydrocarbonReductionRate: [],
                    ...info,
                });
                // increase the current ID
                currentNodeId += 1;
                // incrementally set the coordinates
                if (yValue < 600) {
                    yValue += 120;
                } else {
                    if (xValue < 700) {
                        yValue = 0;
                        xValue += 700;
                    } else {
                        yValue = 0;
                        xValue += 260;
                    }
                }

                /** add Link **/
                links.push(
                    {
                        srcNodeID: +currentNodeId - 2,
                        targetNodeID: +currentNodeId - 1,
                        linkID: currentLinkId.toString(),
                        strokeColor: 'black',
                        isSecondary: false,
                        selected: false,
                        linkFlow: await getSecondaryLinkProps(areaName),
                    },
                    {
                        srcNodeID: +currentNodeId - 1,
                        targetNodeID: eolExist ? 1 : 0,
                        linkID: (currentLinkId + 1).toString(),
                        strokeColor: 'black',
                        isSecondary: false,
                        selected: false,
                        linkFlow: await getSecondaryLinkProps(nodeName),
                    },
                );
                currentLinkId += 2;
            }
        }
    }
    // Pervious Nodes
    for (const index in perviousNodes) {
        const buf =
            mapInfo.state.toUpperCase().includes('QLD') &&
            perviousNodes[index].pAreaType.toUpperCase().includes('GROUND')
                ? 'landscape'
                : perviousNodes[index].pAreaType;
        const areaType = modelCatchment + buf;
        const areaName = perviousNodes[index].pAreaName;
        const areaSize = perviousNodes[index].pAreaSize;
        /** add Source Node **/
        if (areaSize !== '') {
            nodeList.push({
                id: currentNodeId.toString(),
                x: xValue,
                y: yValue,
                isDragging: false,
                img: '',
                label: `${areaName}-${areaSize}sqm-0%-${areaType}`,
                showingNote: false,
                nodeInfo: {
                    'Node Type': 'UrbanSourceNode',
                    'Node Name': areaType,
                    Notes: '',
                    'Custom Name': areaName,
                    'Node Label': `${areaName}-${areaSize}sqm-0%-${areaType}`,
                    'Zoning Surface Type': areaType,
                    'Total Area (m2)': areaSize,
                    'Imperviousness (%)': 0,
                    'Perviousness (%)': 100,
                },
                linkFlowTemplate: await getSecondaryLinkProps(perviousNodes[index].pAreaTreatType),
            });
            // increase the current ID
            currentNodeId += 1;

            /** add Treatment Node **/
            // get Node Name and Node Type
            let nodeName = perviousNodes[index].pAreaTreatType;
            if (nodeName === 'None') {
                links.push({
                    srcNodeID: +currentNodeId - 1,
                    targetNodeID: 0,
                    linkID: currentLinkId.toString(),
                    strokeColor: 'black',
                    isSecondary: false,
                    selected: false,
                    linkFlow: await getSecondaryLinkProps(areaName),
                });
                currentLinkId += 1;
                // incrementally set the coordinates
                if (yValue < 600) {
                    yValue += 120;
                } else {
                    if (xValue < 700) {
                        yValue = 0;
                        xValue += 700;
                    } else {
                        yValue = 0;
                        xValue += 260;
                    }
                }
            } else {
                let nodeName = perviousNodes[index].pAreaTreatType;
                let nodeType = 'GPTNode';
                if (nodeTypeDict[nodeName]) nodeType = nodeTypeDict[nodeName];
                if (treatmentNameDict[nodeName]) nodeName = treatmentNameDict[nodeName];
                let treatmentSize = perviousNodes[index]['pAreaTreatSize'];
                let rainwaterDemand = siteDetails.rainwaterDemand;
                let impAreaBrooms = perviousNodes[index]['pAreaBrooms'];
                const info = getTreatmentInfoTemplate(
                    perviousNodes[index].pAreaTreatType,
                    nodeType,
                    nodeName,
                    treatmentSize,
                    rainwaterDemand,
                    impAreaBrooms,
                );
                nodeList.push({
                    id: currentNodeId.toString(),
                    x: xValue >= 700 ? xValue - 120 : xValue + 120,
                    y: yValue,
                    isDragging: false,
                    img: '',
                    showingNote: false,
                    linkFlowTemplate: await getSecondaryLinkProps(nodeName),
                    hydrocarbonReductionRate: [],
                    ...info,
                });
                // increase the current ID
                currentNodeId += 1;
                // incrementally set the coordinates
                if (yValue < 600) {
                    yValue += 120;
                } else {
                    if (xValue < 700) {
                        yValue = 0;
                        xValue += 700;
                    } else {
                        yValue = 0;
                        xValue += 260;
                    }
                }
                /** add Link **/
                links.push(
                    {
                        srcNodeID: +currentNodeId - 2,
                        targetNodeID: +currentNodeId - 1,
                        linkID: currentLinkId.toString(),
                        strokeColor: 'black',
                        isSecondary: false,
                        selected: false,
                        linkFlow: await getSecondaryLinkProps(areaName),
                    },
                    {
                        srcNodeID: +currentNodeId - 1,
                        targetNodeID: 0,
                        linkID: (currentLinkId + 1).toString(),
                        strokeColor: 'black',
                        isSecondary: false,
                        selected: false,
                        linkFlow: await getSecondaryLinkProps(nodeName),
                    },
                );
                currentLinkId += 2;
            }
        }
    }

    const finalPayload = {
        address: mapInfo,
        basicInfo: basicInfo,
        nodeList: nodeList,
        links: links,
        nodeCounter: currentNodeId,
        linkCounter: currentLinkId,
        backgroundZoom: 1,
        hasBackgroundImage: false,
        finalReportDescription: {},
        finalReportAppendix: [],
    };

    saveModelToDB(basicInfo, finalPayload)
        .then((res) => {
            window.open(
                `https://johnconnor.stormupdated.com.au/?model_id=${res.model_id}`,
                '_blank',
            );
        })
        .catch((error) => console.error(error));
};

export const handleRWTDemandReport = (results, userInfo, mapInfo, projectInfo) => {
    generateRWTDemandReport(results, userInfo, mapInfo, projectInfo).then((res) => {
        initiateReportDownload(res, `${mapInfo.address} - RWT Demand Report.pdf`);
    });
};
