/*
This component is used to render the error Node.

In the auditor, if the node is not compliant for the council, it showed the 
list of errorNode for the council to do modification. 
*/
import React, { useEffect, useRef } from "react";
// react-bootstrap
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function ErrorNode(props) {
  const message = useRef("");

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {message.current}
    </Tooltip>
  );

  function handleInputChange(e, id, list) {
    var errorList = list;
    var thisErrorIndex = errorList.findIndex((err) => err.nodeID === id);
    errorList[thisErrorIndex] = {
      ...errorList[thisErrorIndex],
      standard: e.target.value,
    };
    props.setUseStateErrors(true);
    props.setFullErrors(errorList);

    var data = [];
    errorList.forEach((node) => {
      if (node.standard === "No change") {
        node.standard = "";
      }
      var item = {
        node_index: node.nodeID,
        path: node.standard,
      };
      data.push(item);
    });
    props.setSarahEmailSent(false);
    props.setMsfCheckerData(data);
  }

  useEffect(() => {
    if (props.error.nodeMessage.includes("Wetland")) {
      message.current =
        "We are currently working on providing the ability to make this node compliant. In the meantime, please refer to Council engineer or modelling rules.";
    } else if (
      !props.error.nodeMessage.includes("Wetland") &&
      props.error.paths.length === 0
    ) {
      message.current =
        "Council does not accept this node. Please revise model or contact council for more info.";
    }
  }, []);

  return (
    <>
      <tr key={props.error.nodeID} className="auditor-errors-table">
        <td>
          <b>{props.error.nodeName}</b>:{" "}
          <div style={{ color: "red" }}>{props.error.nodeMessage}</div>
        </td>
      </tr>
      <tr className="auditor-errors-table-dropdown">
        <td>
          <div data-tip data-for="wetlandWarning">
            Change to Compliant:
            <select
              name="msfStandard"
              onChange={(e) =>
                handleInputChange(
                  e,
                  props.error.nodeID,
                  props.errorsWithPathsList
                )
              }
              defaultValue="No available yet"
              key={props.index}
            >
              {message.current.includes(
                "We are currently working on providing the ability to make this node compliant."
              ) ? (
                <option key={props.index}>Not available yet</option>
              ) : (
                <>
                  <option key={props.index}>No change</option>
                  {props.error.paths.map((path) => (
                    <option key={path.path} value={path.path}>
                      {path.dropdownText}
                    </option>
                  ))}
                </>
              )}
            </select>
            {message.current ? (
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <Button variant="light">?</Button>
              </OverlayTrigger>
            ) : null}
          </div>
        </td>
      </tr>
    </>
  );
}

export default ErrorNode;
