import React from 'react';
import { StyleSheet, View, Page, Text, Image } from '@react-pdf/renderer';
// components
import ReportHeader from '../ReportHeader';
import Table from '../Table';
import ReportFooter from '../ReportFooter';
import SpecImagePages from '../SpecImagePages';
import { TCCalculatorTemplate } from '../tc-calculation/TCCalculatorTemplate';
import { createStyles, defaultStyles } from '../common';

function round(num) {
    return check(
        parseFloat(num)
            .toFixed(2)
            .replace(/\.?0+$/, ''),
    );
}

function check(num) {
    if (num === undefined || num === null || isNaN(num) | (num === '')) return 'N/A';
    return num;
}

function formatObjectName(name) {
    if (name.toLowerCase().includes('area')) return name + ' (m²)';
    if (name.toLowerCase().includes('volume')) return name + ' (m³)';
    // if (name.toLowerCase().includes('psd')) return name + ' *';
    return name;
}



const generateAllTableData = (data) => {
    const AEP_FY_List = {
        1: 1.2,
        2: 1.15,
        5: 1.05,
        10: 1,
        20: 0.94047619,
        50: 0.827083333,
        63.2: 0.8,
    };
    const findFYForAEP = (AEP) => {
        return AEP_FY_List[AEP].toFixed(2) || 'N/A';
    };

    return [
        !data.osd.psd
            ? {
                  columns: [
                      'Pre-Development Site Details',
                      'Area (m²)',
                      'Controlled Area (m²)',
                      'Uncontrolled Area (m²)',
                  ],
                  items: data.osd.preDevAreas.areas.map((obj) => {
                      return [obj.areaName, obj.surfaceArea, 'N/A', 'N/A'];
                  }),
                  styles: createStyles(),
                  cellWidths: ['35%', '20%', '20%', '25%', '25%'],
                  title: 'Catchment List',
              }
            : {},

        {
            columns: [
                'Post-Development Site Details',
                'Area (m²)',
                'Controlled Area (m²)',
                'Uncontrolled Area (m²)',
            ],
            items: data.osd.postDevAreas.areas.map((obj) => {
                return [
                    obj.areaName,
                    obj.surfaceArea,
                    +obj.surfaceArea - +obj.uncontrolled,
                    obj.uncontrolled,
                ];
            }),
            styles: createStyles(),
            cellWidths: ['35%', '20%', '20%', '25%', '25%'],
        },

        data.osd.psd
            ? {
                  columns: ['', 'Pre-Development', 'Post-Development'],
                  items: data.osdResult.report.site_calcs.map((obj) => {
                      if (obj.double_cols || obj.triple_cols) {
                          return [obj.name, round(obj.value)];
                      }

                      return [formatObjectName(obj.name), 'N/A', round(obj.post_dev)];
                  }),
                  cellWidths: ['44%', '28%', '28%'],
                  customCellWidths: data.osdResult.report.site_calcs.reduce(
                      (result, obj, index) => {
                          if (obj.double_cols || obj.triple_cols) {
                              result[index] = ['44%', '56%'];
                          }
                          return result;
                      },
                      {},
                  ),
                  styles: createStyles(),
                  title: 'Catchment Details',
              }
            : {
                  columns: [
                      '',
                      'Pre-Development',
                      'Post-Development Controlled',
                      'Post-Development Uncontrolled',
                  ],

                  items: [...data.osdResult.report.site_calcs].flatMap((obj, index) => {
                      if (obj.name) {
                          if (obj.double_cols || obj.triple_cols) {
                              return [[formatObjectName(obj.name), round(obj.value)]];
                          }

                          if (obj.name.toLowerCase().includes('tc (min)')) {
                              data.osdResult.report.site_calcs.splice(index, 0, {
                                  adoptedTCrow: true,
                              });

                              return [
                                  [
                                      formatObjectName(obj.name),
                                      round(+obj.pre_dev),
                                      obj.post_dev
                                          ? round(+obj.post_dev)
                                          : round(obj.tc_post_adopted),
                                      round(+obj.uncontrolled),
                                  ],
                                  ['Adoped Tc Post', 'N/A', round(obj.tc_post_adopted)],
                              ];
                          }
                          if (obj.name === 'C10') {
                              return [
                                  [
                                      formatObjectName(obj.name),
                                      round(obj.pre_dev),
                                      round(obj.post_dev),
                                      round(obj.uncontrolled),
                                  ],
                                  [
                                      'Runoff Coefficient (Cy)',
                                      round(data.osdResult.report.runoff_coefficient.pre_dev),
                                      round(
                                          data.osdResult.report.runoff_coefficient.post_dev
                                              .controlled,
                                      ),
                                      round(
                                          data.osdResult.report.runoff_coefficient.post_dev
                                              .uncontrolled,
                                      ) || 'N/A',
                                  ],
                              ];
                          }
                          return [
                              [
                                  formatObjectName(obj.name),
                                  round(obj.pre_dev),
                                  round(obj.post_dev),
                                  round(obj.uncontrolled),
                              ],
                          ];
                      } else return [[]];
                  }),

                  cellWidths: ['40%', '20%', '20%', '20%'],
                  customCellWidths: [...data.osdResult.report.site_calcs].reduce(
                      (result, obj, index) => {
                          if (obj.double_cols || obj.triple_cols) {
                              result[index] = ['40%', '60%'];
                          }
                          if (obj.adoptedTCrow) {
                              data.osdResult.report.site_calcs.splice(index, 1);
                              result[index + 1] = ['40%', '20%', '40%'];
                          }
                          return result;
                      },
                      {},
                  ),
                  styles: createStyles(),
              },

        {
            columns: ['', 'Pre-Development', 'Post-Development'],
            items: [
                ['AEP (%)', data.osd.preDevDesignStorm, data.osd.postDevDesignStorm],
                [
                    'Frequency factor, Fy',
                    findFYForAEP(data.osd.preDevDesignStorm),
                    findFYForAEP(data.osd.postDevDesignStorm),
                ],
                [
                    'Intensity (mm/hr)',
                    round(
                        data.osdResult.report.storm_details.find(
                            (obj) => obj.name === 'Intensity Pre (mm/hr)',
                        ).value,
                    ),
                    round(
                        data.osdResult.report.storm_details.find(
                            (obj) => obj.name === 'Intensity Post (mm/hr)',
                        ).value,
                    ),
                ],
                [
                    'Peak Site Flow (ls)',
                    round(
                        data.osdResult.report.flow_and_vol.find(
                            (obj) => obj.name === 'Pre development peak site inflow (L/s)',
                        ).value,
                    ),
                    round(
                        data.osdResult.report.flow_and_vol.find(
                            (obj) => obj.name === 'Post development peak site inflow (L/s)',
                        ).value,
                    ),
                ],
                [
                    'Tc',
                    data.osd.hasTcPre && !data.osd.psd
                        ? round(+data.osd.tcPre)
                        : round(data.osdResult.report.Tc.Tc_pre),
                    data.osd.hasTcPost && !data.osd.psd
                        ? round(+data.osd.tcPost)
                        : round(data.osdResult.report.Tc.Tc_post),
                ],
            ],
            styles: createStyles({
                ...defaultStyles,
                headerFontSize: 11,
                headerTextAlign: 'center',
                tableWidth: '80%',
            }),
            cellWidths: ['40%', '30%', '30%'],
            title: 'Peak Flow Calculations & Storm Details',
        },
        {
            columns: [
                'PSD/Qpre (L/s)',
                data.osd.psd
                    ? data.osd.councilPSD
                    : round(
                          data.osdResult.report.flow_and_vol.find(
                              (obj) => obj.name === 'Pre development peak site inflow (L/s)',
                          ).value,
                      ),
            ],
            items: [
                ['Critical Duration (min)', data.osdResult.vreq_n_max_data.t_dur.toFixed(2)],
                ['Adopted qpeak', data.osdResult.vreq_n_max_data.q_post_tn.toFixed(2)],
                ['Required Storage Volume (m³)', data.osdResult.vreq_n_max_data.Vreq_n.toFixed(2)],
                [
                    'Climate Change Adjustment (% change from required storage volume)',
                    data.osd.climateChangeRate,
                ],
                ['Adjusted Required Storage Volume(m³)', data.osdResult.vreq_n_max.toFixed(2)],
            ],
            styles: createStyles({
                ...defaultStyles,
                tableWidth: '70%',
            }),
            cellWidths: ['50%', '50%'],
            title: 'Permissible Site Discharge & On Site Detention Volume',
        },

        data.osdResult.report.orifice.tankHeight
            ? {
                  columns: [
                      'Height of Storage Above Orifice (m)',
                      round(data.osdResult.report.orifice.tankHeight),
                  ],
                  items: [
                      [
                          'Orifice Diameter (mm)',
                          round(data.osdResult.report.orifice.orificeDiameter),
                      ],
                  ],
                  cellWidths: ['70%', '30%'],
                  styles: createStyles({
                      ...defaultStyles,
                      textAlignment: 'left',
                      headerTextAlign: 'left',
                      tableWidth: '45%',
                  }),
                  title: 'Orifice Calculation',
              }
            : {},
    ];
};

export default function ModifiedRationalReportTemplate({
    data,
    imageSource,
    manufactureImageLinks,
    manufactureDocuments,
}) {
    const styles = StyleSheet.create({
        body: {
            paddingTop: 30,
            paddingBottom: 30,
            paddingHorizontal: 35,
            marginBottom: 10,
        },
        section: {
            marginBottom: 10,
            padding: 15,
            display: 'flex',
            flexDirection: 'row',
        },
        mainTitle: {
            fontSize: 18,
            fontweight: 1000,
            textAlign: 'center',
            marginTop: 5,
            marginBottom: 5,
        },
        catchmentTimes: {
            marginTop: 10,
            fontSize: 15,
            width: '100%',
            justifyContent: 'end',
            display: 'flex',
        },
        disclaimer: {
            fontSize: 9,
            marginTop: 15,
            lineHeight: 1.3,
        },
    });

    return (
        <>
            <Page style={styles.body} wrap>
                <ReportHeader reportHeaderUrl={data.reportHeaderIconUrl} />
                <View style={{ pageBreakAfter: 'always' }}>
                    <Text style={styles.mainTitle}>OSD Design Summary Report</Text>
                    <Text style={{ fontSize: 12, marginTop: 10 }}>
                        Method for OSD calculations: MODIFIED RATIONAL METHOD
                    </Text>
                </View>
                <View>
                    <Text style={{ fontSize: 12, marginTop: 10 }}>
                        OSD Device Type:{data.OSDMaterial} {data.storageType}
                    </Text>
                </View>
                <View>
                    <Text style={{ fontSize: 12, marginTop: 10 }}>
                        OSD Device Name: {data.OSDCompany}
                    </Text>
                </View>
                {/* TC Calculator */}
                <TCCalculatorTemplate
                    tcData={data.osd.tcData}
                    createStyles={createStyles}
                    defaultStyles={defaultStyles}
                />
                {/* Site Details */}
                <View style={{ marginBottom: 50 }}>
                    {generateAllTableData(data).map((table, index) => {
                        if (table.styles) {
                            return (
                                <Table
                                    columnNames={table.columns}
                                    tableData={table.items}
                                    headerWidths={table.headerWidths}
                                    cellWidths={table.cellWidths}
                                    customCellWidths={table.customCellWidths}
                                    tableStyles={table.styles}
                                    title={table.title}
                                />
                            );
                        }
                    })}

                    <Text style={styles.disclaimer}>
                        NOTE:{'\n'} * Uncontrolled areas are assumed to be accounted for in the
                        calculation of PSD when PSD is provided by Council.
                    </Text>
                </View>
                <ReportFooter />
            </Page>

            <SpecImagePages
                data={data}
                osdCompany={data.OSDCompany}
                manufactureImageLinks={manufactureImageLinks}
                manufactureDocuments={manufactureDocuments}
                reportHeaderUrl={data.reportHeaderIconUrl}
            />

            <Page style={styles.body} wrap>
                <ReportHeader reportHeaderUrl={data.reportHeaderIconUrl} />
                <Text style={styles.mainTitle}>Required Storage Volume (Vreq) Calculation</Text>
                <View style={{ marginTop: 30 }} fixed>
                    <Image src={imageSource} />
                </View>
                <ReportFooter />
            </Page>
        </>
    );
}
