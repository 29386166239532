import React from 'react';
import { Document, Page, StyleSheet, pdf, View } from '@react-pdf/renderer';
// utils
import {
    getManufactureDeviceDocumentLinks,
    getReportTemplateByManufactureDevice,
} from '../../rdsCmsUtils';
import { getFileFromS3Bucket } from '../../s3Utils';
import { treatmentNameDict } from '../../globalConstant';
// components
import ReportFooter from '../ReportFooter';
import ReportHeader from '../ReportHeader';
import BasicInformation from '../BasicInformation';
import StormRatingAndOverallReduction from '../StormRatingAndOverallReduction';
import OverallReductionsAndResults from '../OverallReductionsAndResults';
import SuCalculatorReportTemplate from './SuCalculatorReportTemplate';
import ProjectDescription from '../report-note-section/ProjectDescription';
import ReportAppendixs from '../report-note-section/ReportAppendixs';

// methods
const getSuCalculatorReport = (
    userInfo,
    projectInfo,
    mapInfo,
    wqMethod,
    hasWQ,
    impervious,
    pervious,
    overallResults,
    targetReductions,
    treatmentNodesObject,
    reportTemplates,
    manufactureDeviceImages,
    manufactureDeviceDocumentLinks,
    imperviousNodes,
    perviousNodes,
    postDevImperviousNodes,
    postDevPerviousNodes,
    eolTreatment,
    eolTreatmentSize,
    reportHeaderIconUrl,
    treatmentTrainsImageUrl,
    reportNoteData,
) => {
    const styles = StyleSheet.create({
        body: {
            paddingTop: 30,
            paddingBottom: 10,
            paddingHorizontal: 35,
        },
        footerSection: {
            position: 'absolute',
            width: '100vw',
            bottom: 10,
            textAlign: 'center',
            marginHorizontal: 'auto',
        },
    });

    return (
        <Document>
            {/* Basic Information */}
            <BasicInformation
                reportHeaderUrl={reportHeaderIconUrl}
                address={{
                    suburb: mapInfo.suburb,
                    state: mapInfo.state,
                    postcode: mapInfo.postcode,
                    council: mapInfo.council,
                    address: mapInfo.address,
                    rainfallStation: mapInfo.rainfallStation,
                    coordinate: mapInfo.coordinate,
                }}
                basicInfo={{
                    developmentType: projectInfo.developmentType,
                    permit: projectInfo.planningPermitNo,
                    assessor: userInfo.assessorName,
                    email: userInfo.assessorEmail,
                }}
            />

            {reportNoteData.useReportNote &&
                reportNoteData.projectDescriptionSelection !== 'no-description' && (
                    <ProjectDescription reportNoteData={reportNoteData} />
                )}

            {/* Storm Rating and Overall Reduction */}
            {hasWQ && (
                <Page style={styles.body} size="A4">
                    <View>
                        <ReportHeader reportHeaderUrl={reportHeaderIconUrl} />
                    </View>
                    <StormRatingAndOverallReduction
                        wqMethod={wqMethod}
                        overallResults={overallResults}
                        targetReductions={targetReductions}
                    />
                    {wqMethod.includes('NorBE') && wqMethod.includes('Dorries') && (
                        <OverallReductionsAndResults
                            overallResults={overallResults}
                            targetReductions={targetReductions}
                        />
                    )}
                    <View style={styles.footerSection}>
                        <ReportFooter />
                    </View>
                </Page>
            )}
            {/* Su Calculator Report */}
            <SuCalculatorReportTemplate
                wqMethod={wqMethod}
                hasWQ={hasWQ}
                impervious={impervious}
                pervious={pervious}
                overallResults={overallResults}
                treatmentNodesObject={treatmentNodesObject}
                reportTemplates={reportTemplates}
                manufactureDeviceImages={manufactureDeviceImages}
                manufactureDeviceDocumentLinks={manufactureDeviceDocumentLinks}
                imperviousNodes={imperviousNodes}
                perviousNodes={perviousNodes}
                postDevImperviousNodes={postDevImperviousNodes}
                postDevPerviousNodes={postDevPerviousNodes}
                eolTreatment={eolTreatment}
                eolTreatmentSize={eolTreatmentSize}
                reportHeaderIconUrl={reportHeaderIconUrl}
                treatmentTrainsImageUrl={treatmentTrainsImageUrl}
            />
            {reportNoteData.useReportNote && (
                <ReportAppendixs reportNoteAppendixItems={reportNoteData.reportNoteAppendixItems} />
            )}
        </Document>
    );
};

/**
 *
 * collecting the same treatment type nodes info into an array
 *
 * @param {Array} imperviousNodes  // Array of impervious nodes
 * @param {Array} perviousNodes  // Array of pervious nodes
 *
 * @returns {Object}  // Object of each treatment type including nodes information
 */
const collectingNodeInfoByTreatmentType = (imperviousNodes, perviousNodes) => {
    let nodesObject = {};
    const assignNodeByTreatmentType = (treatmentDeviceType, node) => {
        const convertedTreatmentName = !treatmentDeviceType.includes('Rainwater Tank -')
            ? treatmentNameDict[treatmentDeviceType]
            : treatmentDeviceType;
        if (nodesObject[convertedTreatmentName]) {
            nodesObject = {
                ...nodesObject,
                [convertedTreatmentName]: [...nodesObject[convertedTreatmentName], node],
            };
        } else {
            nodesObject = {
                ...nodesObject,
                [convertedTreatmentName]: [node],
            };
        }
    };
    // Impervious Nodes
    for (const node of imperviousNodes) {
        const treatmentDeviceType = node['impAreaTreatType'];
        assignNodeByTreatmentType(treatmentDeviceType, node);
    }
    // Pervious Nodes
    for (const node of perviousNodes) {
        const treatmentDeviceType = node['pAreaTreatType'];
        assignNodeByTreatmentType(treatmentDeviceType, node);
    }

    return nodesObject;
};
/**
 * Fetch treatment types template from AWS RDS -cms
 *
 * @param {Object} data  // Object of each treatment type including nodes information
 *
 * @returns {Object} // Object of each treatment type including image link
 */
const getReportTemplates = async (data) => {
    let fetchedReportTemplates = {};
    const promises = Object.keys(data).map((deviceType) => {
        if (deviceType !== 'none') {
            return getReportTemplateByManufactureDevice(deviceType.toLowerCase())
                .then((res) => {
                    fetchedReportTemplates = {
                        ...fetchedReportTemplates,
                        [deviceType]: res[0]['template_name'],
                    };
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        return null;
    });

    await Promise.all(promises);
    return fetchedReportTemplates;
};
/**
 * Fetch treatment types image from AWS S3
 * Bucket Name: manufacture-device-images
 *
 * @param {Object} data  // Object of each treatment type including nodes information
 *
 * @returns {Object} // Object of each treatment type including image link
 */
const getManufactureDeviceImages = async (data) => {
    let imagesBuf = {};
    const promises = Object.keys(data).map((deviceType) => {
        if (deviceType !== 'none') {
            return getFileFromS3Bucket(
                'manufacture-device-images',
                `${deviceType.toLowerCase()}.png`,
            )
                .then((res) => {
                    imagesBuf = { ...imagesBuf, [deviceType]: res };
                })
                .catch((err) => {
                    console.log(err);
                });
        } else return null;
    });

    // return the imagesBuf after all promises are resolved
    await Promise.all(promises);
    const fetchData = async (imageUrl) => {
        try {
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            const reader = new FileReader();
            reader.onloadend = () => {
                return reader.result;
            };
            reader.readAsDataURL(blob);
        } catch (error) {
            console.error('Error loading image:', error);
        }
    };

    let fetchImages = {};
    for (const key in imagesBuf) {
        fetchImages = { ...fetchImages, [key]: fetchData(imagesBuf[key]) };
    }
    return imagesBuf;
};
/**
 * Fetch treatment type documents from AWS RDS - cms
 *
 * @param {Object} data  // Object of each treatment type including nodes information
 *
 * @returns {Object} // Object of each treatment type including document links
 */
const getDocumentLinks = async (data) => {
    let fetchedLinks = {};
    const promises = Object.keys(data).map((deviceType) => {
        if (deviceType.toLowerCase() !== 'none') {
            return getManufactureDeviceDocumentLinks(deviceType.toLowerCase())
                .then((res) => {
                    fetchedLinks = { ...fetchedLinks, [deviceType]: res };
                })
                .catch((err) => {
                    console.log(err);
                });
        } else return null;
    });

    await Promise.all(promises);
    return fetchedLinks;
};

export const generateSuCalculatorReport = async (
    userInfo,
    projectInfo,
    mapInfo,
    wqMethod,
    hasWQ,
    impervious,
    pervious,
    overallResults,
    targetReductions,
    imperviousNodes,
    perviousNodes,
    postDevImperviousNodes,
    postDevPerviousNodes,
    eolTreatment,
    eolTreatmentSize,
    reportHeaderIconUrl,
    treatmentTrainsImageUrl,
    reportNoteData,
) => {
    const treatmentNodesObject = collectingNodeInfoByTreatmentType(imperviousNodes, perviousNodes);
    const reportTemplates = await getReportTemplates(treatmentNodesObject);
    const manufactureDeviceImages = await getManufactureDeviceImages(treatmentNodesObject);
    const manufactureDeviceDocumentLinks = await getDocumentLinks(treatmentNodesObject);

    try {
        const reportBlob = await pdf(
            getSuCalculatorReport(
                userInfo,
                projectInfo,
                mapInfo,
                wqMethod,
                hasWQ,
                impervious,
                pervious,
                overallResults,
                targetReductions,
                treatmentNodesObject,
                reportTemplates,
                manufactureDeviceImages,
                manufactureDeviceDocumentLinks,
                imperviousNodes,
                perviousNodes,
                postDevImperviousNodes,
                postDevPerviousNodes,
                eolTreatment,
                eolTreatmentSize,
                reportHeaderIconUrl,
                treatmentTrainsImageUrl,
                reportNoteData,
            ),
        ).toBlob();

        return reportBlob;
    } catch (err) {
        console.error(err);
    }
};
