import React from 'react';
import { StyleSheet, View, Page, Text, Image } from '@react-pdf/renderer';

import ReportHeader from '../ReportHeader';
import Table from '../Table';
import ReportFooter from '../ReportFooter';
import { TCCalculatorTemplate } from '../tc-calculation/TCCalculatorTemplate';

// using https://lingojam.com/SuperscriptGenerator to generate superscripts (<sup>2</sup> is not accept by React PDF)

function round(num) {
    return check(
        parseFloat(num)
            .toFixed(2)
            .replace(/\.?0+$/, ''),
    );
}

function check(num) {
    if (num === undefined || num === null || isNaN(num) | (num === '')) return 'N/A';
    return num;
}

const getClimatePercentage = (num) => {
    if (num === 1) {
        return 'No climate change adjustment';
    } else if (num < 1) {
        return '-' + round((1 - num) * 100) + '%';
    } else {
        return '+' + round((num - 1) * 100) + '%';
    }
};

function getAdjustedStorageVolume(num, adjustment) {
    return round(num * adjustment);
}

function formatObjectName(name) {
    if (name.toLowerCase().includes('area')) return name + ' (m²)';
    if (name.toLowerCase().includes('volume')) return name + ' (m³)';
    // if (name.toLowerCase().includes('psd')) return name + ' *';
    return name;
}

const defaultStyles = {
    borderStyle: '1px solid #658cbb',
    textAlignment: 'center',
    headerBorder: true,
    fontSize: 9,
    headerFontSize: 9,
    headerTextAlign: 'center',
    tableWidth: '100%',
    marginTop: 15,
    headerTextDecoration: 'none',
};

const createStyles = (newStyles = defaultStyles) => {
    return {
        section: { marginTop: newStyles.marginTop },
        table: {
            display: 'table',
            width: newStyles.tableWidth,
            border: newStyles.borderStyle,
            borderBottom: 0,
            borderRight: 0,
            borderLeft: 0,
            borderTop: newStyles.headerBorder ? newStyles.borderStyle : 0,
        },

        tableRow: {
            flexDirection: 'row',
            borderBottom: newStyles.borderStyle,
        },

        headerCell: {
            fontSize: newStyles.headerFontSize,
            textAlign: newStyles.headerTextAlign,
            borderRight: newStyles.headerBorder ? newStyles.borderStyle : 0,
            borderLeft: newStyles.headerBorder ? newStyles.borderStyle : 0,
            height: '100%',
            padding: 5,
            textDecoration: newStyles.headerTextDecoration,
        },

        tableCell: {
            fontSize: newStyles.fontSize,
            textAlign: newStyles.textAlignment,
            borderRight: newStyles.borderStyle,
            borderLeft: newStyles.borderStyle,
            height: '100%',
            padding: 5,
        },
    };
};

const generateAllTableData = (data) => {
    return [
        !data.osd.psd
            ? {
                  columns: [
                      'Pre-Development Site Details',
                      'Area (m²)',
                      'Controlled Area (m²)',
                      'Uncontrolled Area (m²)',
                  ],
                  items: data.osd.preDevAreas.areas.map((obj) => {
                      return [obj.areaName, obj.surfaceArea, 'N/A', 'N/A'];
                  }),
                  styles: createStyles(),
                  cellWidths: ['35%', '20%', '20%', '25%'],
              }
            : {},

        {
            columns: [
                'Post-Development Site Details',
                'Area (m²)',
                'Controlled Area (m²)',
                'Uncontrolled Area (m²)',
            ],
            items: data.osd.postDevAreas.areas.map((obj) => {
                return [
                    obj.areaName,
                    obj.surfaceArea,
                    obj.surfaceArea - obj.uncontrolled,
                    obj.uncontrolled,
                ];
            }),
            styles: createStyles(),
            cellWidths: ['35%', '20%', '20%', '25%'],
        },

        data.osd.psd
            ? {
                  columns: ['', 'Pre-Development', 'Post-Development'],
                  items: data.osdResult.report.site_calcs.map((obj) => {
                      if (obj.double_cols || obj.triple_cols) {
                          return [obj.name, round(obj.value)];
                      }

                      return [formatObjectName(obj.name), 'N/A', round(obj.post_dev)];
                  }),
                  cellWidths: ['44%', '28%', '28%'],
                  customCellWidths: data.osdResult.report.site_calcs.reduce(
                      (result, obj, index) => {
                          if (obj.double_cols || obj.triple_cols) {
                              result[index] = ['44%', '56%'];
                          }
                          return result;
                      },
                      {},
                  ),
                  styles: createStyles(),
              }
            : {
                  columns: [
                      '',
                      'Pre-Development',
                      'Post-Development Controlled',
                      'Post-Development Uncontrolled',
                  ],

                  // using array copy as it is changed during flatMap
                  items: [...data.osdResult.report.site_calcs].flatMap((obj, index) => {
                      if (obj.double_cols || obj.triple_cols) {
                          return [[obj.name, round(obj.value)]];
                      }

                      /* Slope */
                      if (obj.name.toLowerCase().includes('slope')) {
                          return [
                              [
                                  formatObjectName(obj.name),
                                  data.osd.hasTcPre ? 'N/A' : round(obj.pre_dev),
                                  data.osd.hasTcPost ? 'N/A' : round(obj.post_dev),
                                  data.osd.hasTcU ? 'N/A' : round(obj.uncontrolled),
                              ],
                          ];
                      }

                      /* Length of Flow Path */
                      if (obj.name.toLowerCase().includes('length of flow')) {
                          return [
                              [
                                  formatObjectName(obj.name),
                                  data.osd.hasTcPre ? 'N/A' : round(obj.pre_dev),
                                  data.osd.hasTcPost ? 'N/A' : round(obj.post_dev),
                                  data.osd.hasTcU ? 'N/A' : round(obj.uncontrolled),
                              ],
                          ];
                      }

                      /* TC */
                      if (obj.name.toLowerCase().includes('tc')) {
                          // Returning two objects in this case, so adding dummy value to data
                          // to make sure customWidths render correctly.
                          // Assumes items is declared before customCellWidths in JS
                          data.osdResult.report.site_calcs.splice(index, 0, {
                              adoptedTCrow: true,
                          });

                          return [
                              [
                                  formatObjectName(obj.name),
                                  data.osd.hasTcPre ? round(data.osd.tcPre) : round(obj.pre_dev),
                                  data.osd.hasTcPost ? round(data.osd.tcPost) : round(obj.post_dev),
                                  data.osd.hasTcU ? round(data.osd.tcU) : round(obj.uncontrolled),
                              ],
                              ['Adoped Tc Post', 'N/A', round(obj.tc_post_adopted)],
                          ];
                      }

                      return [
                          [
                              formatObjectName(obj.name),
                              round(obj.pre_dev),
                              round(obj.post_dev),
                              round(obj.uncontrolled),
                          ],
                      ];
                  }),

                  cellWidths: ['40%', '20%', '20%', '20%'],
                  customCellWidths: [...data.osdResult.report.site_calcs].reduce(
                      (result, obj, index) => {
                          if (obj.double_cols || obj.triple_cols) {
                              result[index] = ['40%', '60%'];
                          }
                          if (obj.adoptedTCrow) {
                              // removing the placeholder obj adding in during items above (see last comment ^)
                              data.osdResult.report.site_calcs.splice(index, 1);
                              result[index + 1] = ['40%', '20%', '40%'];
                          }
                          return result;
                      },
                      {},
                  ),
                  styles: createStyles(),
              },
        // Storm Details
        {
            columns: ['Storm Details'],
            items: data.osdResult.report.storm_details.map((obj) => [obj.name, round(obj.value)]),
            styles: createStyles({
                ...defaultStyles,
                headerBorder: false,
                headerFontSize: 13,
                headerTextAlign: 'left',
                tableWidth: '50%',
            }),
            cellWidths: ['70%', '30%'],
        },
        // Runoff Coefficient (Cy)
        {
            columns: ['Runoff Coefficient (Cy)'],
            items: data.osd.psd
                ? [
                      ['Pre Dev', round(data.osdResult.report.runoff_coefficient.pre_dev)],
                      ['Post Dev', round(data.osdResult.report.runoff_coefficient.post_dev)],
                  ]
                : [
                      ['Pre Dev', round(data.osdResult.report.runoff_coefficient.pre_dev)],
                      [
                          'Post Dev Controlled',

                          round(data.osdResult.report.runoff_coefficient.post_dev.controlled),
                      ],
                      [
                          'Post Dev Uncontrolled',

                          round(data.osdResult.report.runoff_coefficient.post_dev.uncontrolled),
                      ],
                  ],
            styles: createStyles({
                ...defaultStyles,
                headerBorder: false,
                headerFontSize: 13,
                headerTextAlign: 'left',
                tableWidth: '50%',
            }),
            headerWidths: ['100%'],
            cellWidths: ['70%', '30%'],
        },
        // Flow and Volume
        {
            columns: ['Flow and Volume'],
            items: data.osdResult.report.flow_and_vol
                .map((obj) => [formatObjectName(obj.name), round(obj.value)])
                .concat([
                    data.osd.climateAdjustment !== '1'
                        ? [
                              'Climate Change (% increase of required storage volume):',
                              getClimatePercentage(parseFloat(data.osd.climateAdjustment)),
                          ]
                        : [],
                    data.osd.climateAdjustment !== '1'
                        ? [
                              'Adjusted Required Storage Volume (m³):',
                              getAdjustedStorageVolume(
                                  data.osdResult.max_volume,
                                  data.osd.climateAdjustment,
                              ),
                          ]
                        : [],
                ]),
            styles: createStyles({
                ...defaultStyles,
                headerBorder: false,
                headerFontSize: 13,
                headerTextAlign: 'left',
                tableWidth: '70%',
            }),
            cellWidths: ['70%', '30%'],
        },
        // Orifice Calculation
        data.osdResult.report.orifice.tankHeight
            ? {
                  columns: ['Orifice Calculation'],
                  items: [
                      [
                          'Height of Storage Above Orifice (m)',
                          round(data.osdResult.report.orifice.tankHeight),
                      ],
                      [
                          'Orifice Diameter (mm)',
                          round(data.osdResult.report.orifice.orificeDiameter),
                      ],
                  ],
                  cellWidths: ['70%', '30%'],
                  headerWidths: ['100%'],
                  styles: createStyles({
                      ...defaultStyles,
                      textAlignment: 'left',
                      headerBorder: false,
                      headerFontSize: 13,
                      headerTextAlign: 'left',
                      tableWidth: '45%',
                  }),
              }
            : {},
    ];
};

const RationalReportTemplate = ({ data }) => {
    const styles = StyleSheet.create({
        body: {
            paddingTop: 30,
            paddingBottom: 30,
            paddingHorizontal: 35,
            marginBottom: 10,
        },
        section: {
            marginBottom: 10,
            padding: 15,
            display: 'flex',
            flexDirection: 'row',
        },
        mainTitle: {
            fontSize: 18,
            fontweight: 1000,
            textAlign: 'center',
            marginTop: 5,
            marginBottom: 5,
        },
        catchmentTimes: {
            marginTop: 10,
            fontSize: 15,
            width: '100%',
            justifyContent: 'end',
            display: 'flex',
        },
        disclaimer: {
            fontSize: 9,
            marginTop: 15,
            lineHeight: 1.3,
        },
    });

    return (
        <Page style={styles.body} wrap>
            <ReportHeader reportHeaderUrl={data.reportHeaderIconUrl} />
            <View style={{ pageBreakAfter: 'always' }}>
                <Text style={styles.mainTitle}>OSD Design Summary Report</Text>
                <Text style={{ fontSize: 12, marginTop: 10 }}>
                    Method for OSD calculations: {data.osd.method.toUpperCase()}
                </Text>
            </View>

            {/* OSD System Specified */}
            <View>
                <Text style={{ fontSize: 10, marginTop: 10 }}>Device Name: {data.OSDCompany}</Text>
                <Text style={{ fontSize: 10, marginTop: 10 }}>
                    Device Type: {data.OSDMaterial} - {data.storageType}
                </Text>
            </View>
            {/* ... */}

            {/* TC Calculator */}
            <TCCalculatorTemplate
                tcData={data.osd.tcData}
                createStyles={createStyles}
                defaultStyles={defaultStyles}
            />
            {/* Site Details */}
            <View style={{ marginBottom: 50 }}>
                {generateAllTableData(data).map((table, index) => (
                    <>
                        {table.styles ? (
                            <Table
                                tableKey={index}
                                columnNames={table.columns}
                                tableData={table.items}
                                headerWidths={table.headerWidths}
                                cellWidths={table.cellWidths}
                                customCellWidths={table.customCellWidths}
                                tableStyles={table.styles}
                            />
                        ) : (
                            <></>
                        )}
                    </>
                ))}

                {data.osd.method?.toLowerCase() === 'rational method' ? (
                    data.osd.psd ? (
                        <Text style={styles.disclaimer}>
                            NOTE:{'\n'} * Uncontrolled areas are assumed to be accounted for in the
                            calculation of PSD when PSD is provided by Council.
                        </Text>
                    ) : (
                        <></>
                    )
                ) : (
                    data.osdResult.report.plots?.plot_selected_aep && (
                        <Image src={data.osdResult.report.plots?.plot_selected_aep} />
                    )
                )}
            </View>
            <ReportFooter />
        </Page>
    );
};

export default RationalReportTemplate;
