import axios from "axios";
// utils
import { LAMBDA_API } from "./globalConstant";

export const haveTableInRainwatertankSchema = async (mlb) => {
  const mlbName = mlb
    .toString()
    .split("- ")
    .join("")
    .split("-")
    .join("_")
    .split(" ")
    .join("_")
    .toString()
    .toLowerCase();

  const payload = {
    endpoint: "haveTableInRainwatertankSchema",
    params: { mlbName: mlbName },
  };

  let result = false;
  await axios
    .post(`${LAMBDA_API}/cms`, payload)
    .then((res) => {
      result = res.data.results.length > 0;
    })
    .catch((err) => {
      console.log("haveTableInRainwatertankSchema error!", err);
    });
  return result;
};

export const calculatingCatchmentSize = async (
  mlbName,
  scale,
  demandValue,
  reliability,
  tankSize
) => {
  const payload = {
    endpoint: "calculatingCatchmentSize",
    params: {
      mlbName: mlbName,
      scale: scale,
      demandValue: demandValue,
      reliability: reliability,
      tankSize: tankSize,
    },
  };

  let result;
  await axios
    .post(`${LAMBDA_API}/cms`, payload)
    .then((res) => {
      result = res;
    })
    .catch((err) => {
      return `getBiggestRainwaterDescBoundary error! ${err}`;
    });
  return result;
};
export const calculatingDemandValue = async (
  mlbName,
  ratio,
  catchmentArea,
  reliability,
  tankSize
) => {
  const payload = {
    endpoint: "calculatingDemandValue",
    params: {
      mlbName: mlbName,
      ratio: ratio,
      catchmentArea: catchmentArea,
      reliability: reliability,
      tankSize: tankSize,
    },
  };

  let result;
  await axios
    .post(`${LAMBDA_API}/cms`, payload)
    .then((res) => {
      result = res;
    })
    .catch((err) => {
      return `calculatingDemandValue error! ${err}`;
    });
  return result;
};
export const calculatingReliability = async (
  mlbName,
  scale,
  catchmentArea,
  demandValue,
  tankSize
) => {
  const payload = {
    endpoint: "calculatingReliability",
    params: {
      mlbName: mlbName,
      scale: scale,
      catchmentArea: catchmentArea,
      demandValue: demandValue,
      tankSize: tankSize,
    },
  };

  let result;
  await axios
    .post(`${LAMBDA_API}/cms`, payload)
    .then((res) => {
      result = res;
    })
    .catch((err) => {
      return `calculatingReliability error! ${err}`;
    });
  return result;
};
export const calculatingTankSize = async (
  mlbName,
  scale,
  catchmentArea,
  reliability
) => {
  const payload = {
    endpoint: "calculatingTankSize",
    params: {
      mlbName: mlbName,
      scale: scale,
      catchmentArea: catchmentArea,
      reliability: reliability,
    },
  };

  let result;
  await axios
    .post(`${LAMBDA_API}/cms`, payload)
    .then((res) => {
      result = res;
    })
    .catch((err) => {
      return `calculatingTankSzize error! ${err}`;
    });
  return result;
};
