import React, { useContext } from "react";
import styles from "./LiveResultRow.module.css";
import {
    TooltipContainer,
    TooltipContent,
    TooltipButton,
} from "../../../../styled-components/TooltipComponents";

const LiveResultRow = ({ result, resultUpdateRequired }) => {
    const formattedResult = {
        tn: result?.stormRatingPercTN?.toFixed(1),
        tp: result?.stormRatingPercTP?.toFixed(1),
        tss: result?.stormRatingPercTSS?.toFixed(1),
        gp: result?.stormRatingPercGP?.toFixed(1),
        fr: result?.reductionPercFlow?.toFixed(1),
        wsr: result?.waterSupplyReliability?.toFixed(1),
    };

    const getColor = (currValue) => {
        return currValue <= 0 ? "black" : currValue >= 100 ? "green" : "red";
    };

    return (
        <>
            <div className={styles.liveStormRow}>
                <div className={styles.resultLabel}>
                    LIVE STORM Result
                    <TooltipContainer>
                        <TooltipButton>❓</TooltipButton>
                        <TooltipContent width="10vw">
                            <span>
                                These results correspond to the catchment above
                                and can be employed to fine-tune treatment.
                            </span>
                        </TooltipContent>
                    </TooltipContainer>
                </div>

                {!resultUpdateRequired ? (
                    result?.alert ? (
                        <div className={styles["alert"]}>{result.alert}</div>
                    ) : (
                        <div
                            className={`${styles.resultsWrapper} ${
                                result === null && styles.hidden
                            } `}
                        >
                            <div
                                style={{
                                    color: getColor(formattedResult["tn"]),
                                }}
                            >
                                TN: {formattedResult["tn"] ?? "N/A"}
                            </div>
                            <div
                                style={{
                                    color: getColor(formattedResult["tp"]),
                                }}
                            >
                                TP: {formattedResult["tp"] ?? "N/A"}
                            </div>
                            <div
                                style={{
                                    color: getColor(formattedResult["tss"]),
                                }}
                            >
                                TSS: {formattedResult["tss"] ?? "N/A"}
                            </div>
                            <div
                                style={{
                                    color: getColor(formattedResult["gp"]),
                                }}
                            >
                                GP: {formattedResult["gp"] ?? "N/A"}
                            </div>
                            <div>
                                Flow Reduction: {formattedResult["fr"] ?? "N/A"}
                            </div>
                            <div>
                                Water Supply Reliability (%):{" "}
                                {formattedResult["wsr"] ?? "N/A"}
                            </div>
                        </div>
                    )
                ) : (
                    <div className={styles.loading}>Calculating...</div>
                )}
            </div>
            {result?.warnings &&
                !resultUpdateRequired &&
                result?.warnings.map((warning, index) => (
                    <div
                        className={`${styles.liveStormRow} ${styles.warning}`}
                        key={"warning-" + index}
                    >
                        {warning}
                    </div>
                ))}
        </>
    );
};

export default LiveResultRow;
