// modules
import React, { useState, useRef } from 'react';
import axios from 'axios';
import { Button, Spinner } from 'react-bootstrap';
// css
import MsfUploader from '../assets/js/MsfUploader';
import styles from './Auditor.module.css';

// utils
import { trackGAEvent, LAMBDA_API_NEW } from '../../utils/globalConstant';
import { recordUserLogs } from '../../utils/s3Utils';
import { getTreatmentTrainsImage } from '../../utils/downloadMethods';
import { saveAs } from 'file-saver';
import { generateAuditorReport } from '../../utils/react-pdf/auditor-report/auditorReportGenerator';

// component
import ErrorNode from './ErrorNode';
import NonCompliantCheck from './NonCompliantCheck';
function MusicAuditor({ userInfo, mapInfo, projectInfo, wqMethod, targetReductions }) {
    const [msfData, setMsfData] = useState();
    const [msfFilePath, setMsfFilePath] = useState();
    const [fileName, setFileName] = useState();
    const [norBeError, setNorBeError] = useState(false);
    const [otherMethodError, setOtherMethodError] = useState(false);
    const [isAuditing, setIsAuditing] = useState(false);
    const [showDownloadButton, setShowDownloadButton] = useState(false);
    const [reportBlob, setReportBlob] = useState(null);
    const [audited, setAudited] = useState(false);

    const [nonCompliantNodes, setNonCompliantNodes] = useState([]);
    const [compliantNodes, setCompliantNodes] = useState([]);
    const [nodesNotExistingInDB, setnodesNotExistingInDB] = useState([]);

    const handleRecordUserLogs = (event, file_path = msfFilePath) => {
        recordUserLogs(mapInfo.projectID, 'Auditor', event, file_path).then((res) => res.json());
        //.then((data) => console.log(data));
    };

    const generateReport = async () => {
        const { msf } = msfData;
        const treatmentTrainsImageUrl = await getTreatmentTrainsImage(msf);

        if (compliantNodes.length > 0 || nonCompliantNodes.length > 0) {
            const reportBlob = await generateAuditorReport(
                userInfo,
                projectInfo,
                compliantNodes,
                mapInfo,
                nonCompliantNodes,
                treatmentTrainsImageUrl,
                msf,
                nodesNotExistingInDB,
            );

            if (reportBlob) {
                setReportBlob(reportBlob);
                return reportBlob;
            } else {
                console.error('Failed to generate report.');
            }
        } else {
            console.log(
                'Compliant nodes and non-compliant nodes are empty. Cannot generate report.',
            );
        }
    };

    // post to /AuditMSF to audit the MSF
    const auditMSF = async (loadingData) => {
        let payload = {
            mlb: loadingData.authority,
            msf: loadingData.msf,
        };
        setIsAuditing(true);
        handleRecordUserLogs('Auditing MSF');

        try {
            await LAMBDA_API_NEW.post('/auditor', payload, { timeout: 100000 }).then(
                async (res) => {
                    const extractCompliantNodes = (auditResults, templatesByNodeType) => {
                        const compliantNodes = auditResults.reduce((acc, result) => {
                            if (result.compliantTemplate.length > 0) {
                                acc.push({
                                    nodeName: result.nodeName,
                                    compliantTemplates: result.compliantTemplate.map(
                                        (templateIndex) => {
                                            return templatesByNodeType[result.nodeType][
                                                templateIndex
                                            ];
                                        },
                                    ),
                                    rows: result.allRows,
                                    nodeType: result.nodeType,
                                });
                            }
                            return acc;
                        }, []);

                        return compliantNodes;
                    };
                    const extractNodesNotExistingInDB = (auditResults) => {
                        const nodesNotExistingInDB = auditResults.reduce((acc, result) => {
                            if (
                                result.compliantTemplate.length === 0 &&
                                result.nonCompliantTemplate.length === 0
                            ) {
                                const notExistingInDBNode = {
                                    nodeName: result.nodeName,
                                    nonExistingInDBTemplates: result.nonCompliantTemplate,
                                    nonExistingInDBRows: {},
                                    rows: result.allRows,
                                };

                                // Ensure nonExistingInDBRows is defined
                                result.nonCompliantRows = result.nonCompliantRows || {};

                                acc.push(notExistingInDBNode);
                            }
                            return acc;
                        }, []);

                        return nodesNotExistingInDB;
                    };
                    const extractNonCompliantNodes = (auditResults, templatesByNodeType) => {
                        const nonCompliantNodes = auditResults.reduce((acc, result) => {
                            if (
                                result.compliantTemplate.length < 1 &&
                                result.nonCompliantTemplate.length > 0
                            ) {
                                const nonCompliantNode = {
                                    nodeName: result.nodeName,
                                    nonCompliantTemplates: result.nonCompliantTemplate.map(
                                        (templateIndex) => {
                                            return templatesByNodeType[result.nodeType][
                                                templateIndex
                                            ];
                                        },
                                    ),
                                    nonCompliantRows: {},
                                    rows: result.allRows,
                                };

                                // Ensure nonCompliantRows is defined
                                result.nonCompliantRows = result.nonCompliantRows || {};

                                // Iterate through each non-compliant template
                                if (result.nonCompliantTemplate.length > 0) {
                                    result.nonCompliantTemplate.forEach((template) => {
                                        if (template && template.match_name) {
                                            // Find the matching rows for this template's match_name
                                            const matchingRows =
                                                result.nonCompliantRows[template.match_name];
                                            // Add the rows to nonCompliantRows under the corresponding match_name
                                            if (matchingRows) {
                                                nonCompliantNode.nonCompliantRows[
                                                    template.match_name
                                                ] = matchingRows;
                                            }
                                        }
                                    });
                                }

                                acc.push(nonCompliantNode);
                            }
                            return acc;
                        }, []);

                        return nonCompliantNodes;
                    };

                    const { auditResults, templatesByNodeType } = res.data;

                    const compliantNodes = await extractCompliantNodes(
                        auditResults,
                        templatesByNodeType,
                    );
                    const nonCompliantNodes = await extractNonCompliantNodes(
                        auditResults,
                        templatesByNodeType,
                    );
                    const nodesNotExistingInDB = await extractNodesNotExistingInDB(
                        res.data.auditResults,
                    );
                    setNonCompliantNodes(nonCompliantNodes);
                    setCompliantNodes(compliantNodes);
                    setnodesNotExistingInDB(nodesNotExistingInDB);
                },
            );
            setAudited(true);
        } catch (e) {
            console.log(e);
            alert(
                'The server could not respond. Please try again, or contact Mircea (0433030044) or Greg (0452518727) if the error persists.',
                e,
            );
        } finally {
            setIsAuditing(false);
        }
    };

    const handleAuditMSF = async () => {
        if (msfData && msfData.msf) {
            // if the wqMethod is NorBE
            if (wqMethod === 'NorBE') {
                //  the nodes are including PreDevelopmentNode or PostDevelopmentNode
                if (
                    msfData.msf.includes('Node Type,PreDevelopmentNode') &&
                    msfData.msf.includes('Node Type,PostDevelopmentNode')
                ) {
                    await auditMSF(msfData);
                } else {
                    setNorBeError(true);
                    return;
                }
            } else {
                // if the wqMethod is not NorBE
                // if the MSF didn't include pre and post development nodes
                if (
                    !msfData.msf.includes('Node Type,PreDevelopmentNode') &&
                    !msfData.msf.includes('Node Type,PostDevelopmentNode')
                ) {
                    await auditMSF(msfData);
                    return;
                } else {
                    setOtherMethodError(true);
                }
            }
        } else {
            console.log('error from TheMap.js. The MSF might be empty');
            alert(
                'The MSF could not be uploaded. Please try again, or contact Mircea (0433030044) or Greg (0452518727) if the error persists. ',
            );
        }
    };

    const AuditorHeader = () => {
        return (
            <>
                <h3>☠️ Audit yer MUSIC Model 🏴‍☠️</h3>
                <div className="my-3">
                    Built a model but not sure if it's compliant with the specific guidelines for{' '}
                    {mapInfo.council}?
                </div>
                <div>
                    <i>Auditor of Music: The Legend of Ming</i> ⚔️ is here!
                </div>
                <div>
                    <a href="https://youtu.be/ozXJrmICwt4">
                        HELP - Teach me what all of this means
                    </a>
                </div>
            </>
        );
    };

    return (
        <>
            <AuditorHeader />
            {norBeError ? (
                <span className={styles['error-message']}>
                    The receiving nodes in the uploaded file, <b>{fileName}</b>, is
                    <b> not valid</b>. Please upload another model, using pre-development and
                    post-development end nodes to represent existing and proposed site conditions.
                </span>
            ) : otherMethodError ? (
                <span className={styles['error-message']}>
                    The end node in the uploaded file, <b>{fileName}</b>, is
                    <b> not valid.</b> Please upload another model, using receiving end node to
                    represent proposed site conditions.
                </span>
            ) : null}

            <table className={styles['auditor-table']}>
                {msfData && (
                    <div className={styles['file-details']}>
                        {showDownloadButton && (
                            <button
                                className={styles['download-button']}
                                onClick={() => {
                                    const blob = new Blob([msfData.msf], {
                                        type: 'application/octet-stream',
                                    });
                                    saveAs(blob, fileName);
                                }}
                            >
                                ⇩ Download MSF ⇩
                            </button>
                        )}
                    </div>
                )}
                <div className={styles['auditor-form-section']} style={{ border: 0 }}>
                    <MsfUploader
                        stateName={mapInfo.state}
                        authority={mapInfo.rainfallStation}
                        setMsfData={setMsfData}
                        setMsfFilePath={setMsfFilePath}
                        setFileName={setFileName}
                        setShowDownloadButton={setShowDownloadButton}
                        handleRecordUserLogs={handleRecordUserLogs}
                        msfData={msfData}
                        fileName={fileName}
                        handleAuditMSF={handleAuditMSF}
                        reportBlob={reportBlob}
                        handleDownloadReport={async () => {
                            // runMelody(false);
                            const report = reportBlob || (await generateReport());
                            if (report) {
                                saveAs(report, `${fileName.split('.')[0]}.pdf`);
                            }
                        }}
                        isAuditing={isAuditing}
                        setReportBlob={setReportBlob}
                        audited={audited}
                        setAudited={setAudited}
                    />
                </div>

                <div style={{ textAlign: 'center' }}>
                    {audited && (
                        <div className={styles['auditor-form-section']}>
                            <NonCompliantCheck
                                nonCompliantNodes={nonCompliantNodes}
                                compliantNodes={compliantNodes}
                            />
                        </div>
                    )}
                </div>
            </table>
        </>
    );
}

export default MusicAuditor;
