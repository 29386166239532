// modules
import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { RingLoader } from 'react-spinners';
import {
    RainwaterTankNotification,
    handleNotification,
    clearNotification,
} from '../RwtNotification/RwtNotification';
// utils
import {
    calculatingCatchmentSize,
    calculatingDemandValue,
    calculatingReliability,
    calculatingTankSize,
} from '../../../../utils/sizeRainwaterTankUtils';
import { countClick } from '../../../../utils/s3Utils';

export default function RwtItem({
    id,
    numOfTanks,
    handleInputChange,
    handleClickDeleteButton,
    catchmentArea,
    demandKlDay,
    demandKlYear,
    reliability,
    tankSize,
    mlbName,
    assessorEmail,
}) {
    const [calculatingResult, setCalculatingResult] = useState(false);
    const [notifications, setNotifications] = useState([]); // State for notifications
    const [showCalculateButtonC, setShowCalculateButtonC] = useState(true);
    const [showCalculateButtonR, setShowCalculateButtonR] = useState(true);
    const [showCalculateButtonD, setShowCalculateButtonD] = useState(true);
    const [showCalculateButtonT, setShowCalculateButtonT] = useState(true);

    const addNotification = (message) => {
        handleNotification(
            id,
            message,
            notifications,
            setNotifications,
            setShowCalculateButtonC,
            setShowCalculateButtonR,
            setShowCalculateButtonD,
            setShowCalculateButtonT,
        );
    };

    const removeNotification = (id) => {
        clearNotification(id, notifications, setNotifications);
    };
    /* --- Catchmnent Area Calculation --- */
    const handleCatchmentAreaCalculate = async () => {
        setShowCalculateButtonC(false);
        countClick(assessorEmail, 35);
        /* constants */
        const demandYear = demandKlYear ? (+demandKlYear * 1000) / 365 : 0;
        const demandValue = +demandKlDay * 1000 + demandYear;
        const scale = 2000 / demandValue;
        const tankSizeBuf = +tankSize * scale;
        const reliabilityBuf = +reliability;
        const mlbNameBuf = mlbName
            .toString()
            .split('- ')
            .join('')
            .split('-')
            .join('_')
            .split(' ')
            .join('_')
            .toString()
            .toLowerCase();

        setCalculatingResult(true);
        /* validation */
        let inValidInput = false;
        if (demandValue / tankSizeBuf >= 10) {
            addNotification(
                `The demand is too high for this rainwater tank, the model's mathematics can't handle. Please adjust either tank size or demand.`,
            );
            inValidInput = true;
        }
        if (reliabilityBuf < 35) {
            addNotification(`The reliability shouldn't be less than 35!`);
            inValidInput = true;
        }
        if (inValidInput) {
            setCalculatingResult(false);
            return;
        }

        /* Start The Calculation */
        await calculatingCatchmentSize(mlbNameBuf, scale, demandValue, reliabilityBuf, tankSizeBuf)
            .then((res) => {
                const message = res.data.results.message;
                const result = (res.data.results.result / scale).toFixed(2);
                const resReliability = res.data.results.result.reliability;
                const resCatchment = res.data.results.result.catchmentArea;

                if (resReliability) {
                    handleInputChange(resReliability, id, 'reliability');
                }
                if (resCatchment) {
                    handleInputChange(resCatchment, id, 'catchmentArea');
                }
                if (result !== -1) handleInputChange(result, id, 'catchmentArea');

                if (message) {
                    setTimeout(() => {
                        addNotification(message);
                    }, 500);
                }
                setCalculatingResult(false);
            })
            .catch((err) => {
                addNotification('Error happened while calculating catchment size');
                console.log('Error happened while calculating catchment size', err);
                setCalculatingResult(false);
            });
        setCalculatingResult(false);
        setShowCalculateButtonC(true);
    };
    /* --- Demand Calculation --- */
    const handleDemandsCalculate = async () => {
        setShowCalculateButtonD(false);
        countClick(assessorEmail, 36);
        /* Constants */
        const catchmentAreaBuf = +catchmentArea;
        const tankSizeBuf = +tankSize;
        const reliabilityBuf = +reliability;
        const ratio = +tankSizeBuf / +catchmentArea;
        const mlbNameBuf = mlbName
            .toString()
            .split('- ')
            .join('')
            .split('-')
            .join('_')
            .split(' ')
            .join('_')
            .toString()
            .toLowerCase();

        /* Validation */
        if (reliabilityBuf < 35) {
            addNotification(`The reliability shouldn't be less than 35!`);
            setCalculatingResult(false);
            return;
        }

        /* Start The Calculation */
        setCalculatingResult(true);
        await calculatingDemandValue(
            mlbNameBuf,
            ratio,
            catchmentAreaBuf,
            reliabilityBuf,
            tankSizeBuf,
        )
            .then((res) => {
                const message = res.data.results.message;
                const result = res.data.results.result.toFixed(2);
                console.log({ message: message, result: result });
                if (result !== -1) handleInputChange(result, id, 'demandKlDay');
                if (message)
                    setTimeout(() => {
                        addNotification(message);
                    }, 500);

                setCalculatingResult(false);
            })
            .catch((err) => {
                addNotification('Error happened while calculating demand value');
                console.log('Error happened while calculating demand value', err);
                setCalculatingResult(false);
            });
        setCalculatingResult(false);
        setShowCalculateButtonD(true);
    };
    /* --- Reliability Calculation --- */
    const handleReliabilityCalculate = async () => {
        setShowCalculateButtonR(false);
        countClick(assessorEmail, 37);
        /* Constants */
        const demandYear = demandKlYear ? (+demandKlYear * 1000) / 365 : 0;
        const demandValue = +demandKlDay * 1000 + demandYear;
        const scale = 2000 / demandValue;
        const catchmentAreaBuf = +catchmentArea * scale;
        const tankSizeBuf = +tankSize * scale;
        const mlbNameBuf = mlbName
            .toString()
            .split('- ')
            .join('')
            .split('-')
            .join('_')
            .split(' ')
            .join('_')
            .toString()
            .toLowerCase();

        setCalculatingResult(true);
        /* validation */
        console.log('Checking with the validation...');
        let inValidInput = false;
        if (demandValue / +tankSizeBuf >= 10) {
            addNotification(
                `The demand is too high for this rainwater tank, the model's mathematics can't handle. Please adjust either tank size or demand.`,
            );
            inValidInput = true;
        }
        if (+tankSizeBuf < 1000) {
            addNotification(
                'Tank size too small, please solve this problem with a couple of buckets.',
            );
            inValidInput = true;
        }

        // shutdown the process
        if (inValidInput) {
            setCalculatingResult(false);
            return;
        }

        /* Start The Calculation */
        await calculatingReliability(mlbNameBuf, scale, catchmentAreaBuf, demandValue, tankSizeBuf)
            .then((res) => {
                const message = res.data.results.message;
                const result = Math.trunc(res.data.results.result);
                if (result !== -1) handleInputChange(result, id, 'reliability');
                if (
                    message &&
                    message === 'This is a conservative reliability.' &&
                    result !== 100
                ) {
                    setTimeout(() => {
                        addNotification(message);
                    }, 500);
                } else if (message && result !== 100)
                    setTimeout(() => {
                        addNotification(message);
                    }, 500);

                setCalculatingResult(false);
            })
            .catch((err) => {
                addNotification('Error happened while calculating reliabilitiy');
                console.log('Error happened while calculating reliabilitiy', err);
                setCalculatingResult(false);
            });
        setCalculatingResult(false);
        setShowCalculateButtonR(true);
    };
    /* --- Tank Size Calculation --- */
    const handleTankSizeCalculate = async () => {
        setShowCalculateButtonT(false);

        countClick(assessorEmail, 38);
        // Constants
        const demandYear = demandKlYear ? (+demandKlYear * 1000) / 365 : 0;
        const demandValue = +demandKlDay * 1000 + demandYear;
        const scale = 2000 / demandValue;
        let catchmentAreaBuf = +catchmentArea * scale;
        const reliabilityBuf = +reliability;
        const mlbNameBuf = mlbName
            .toString()
            .split('- ')
            .join('')
            .split('-')
            .join('_')
            .split(' ')
            .join('_')
            .toString()
            .toLowerCase();

        setCalculatingResult(true);
        /* validation */
        console.log('Checking validation...');
        let inValidInput = false;
        if (reliabilityBuf < 35) {
            addNotification(`The reliability shouldn't be less than 35!`);
            inValidInput = true;
        }
        if (inValidInput) {
            setCalculatingResult(false);
            return;
        }
        /* Start The Calculation */
        await calculatingTankSize(mlbNameBuf, scale, catchmentAreaBuf, reliabilityBuf)
            .then((res) => {
                const message = res.data.results.message;
                const result = Math.trunc(res.data.results.result / scale);
                console.log({ message: message, result: result });
                if (result !== -1) handleInputChange(result, id, 'tankSize');
                if (message)
                    setTimeout(() => {
                        addNotification(message);
                    }, 500);

                setCalculatingResult(false);
            })
            .catch((err) => {
                addNotification('Error happened while calculating tankSize');
                console.log('Error happened while calculating tankSize', err);
                setCalculatingResult(false);
            });
        setCalculatingResult(false);
        setShowCalculateButtonT(true);
    };

    return (
        <div className="flex-1 w-100 d-flex flex-column align-items-center" style={{ gap: 2 }}>
            <div className="flex-1 w-100 d-flex flex-row" style={{ gap: 5 }}>
                {/* Catchment Area */}
                <div
                    className="flex-4 d-flex flex-row"
                    style={{
                        paddingInline: '3px',
                        borderRight: '1px solid black',
                        gap: 5,
                    }}
                >
                    <Form.Control
                        size="sm"
                        id="size-rainwater-tank-tool-catchment-area"
                        name="size-rainwater-tank-tool-catchment-area"
                        value={catchmentArea}
                        placeholder="sqm"
                        onChange={(e) => {
                            handleInputChange(e.target.value, id, 'catchmentArea');
                        }}
                    />
                    {/* Display "Calculate" button or loading spinner based on showCalculateButton */}
                    {showCalculateButtonC ? (
                        <Button
                            type="button"
                            size="sm"
                            variant="success"
                            onClick={() => {
                                handleCatchmentAreaCalculate();
                            }}
                            disabled={
                                (!demandKlDay && !demandKlYear) ||
                                !reliability ||
                                !tankSize ||
                                calculatingResult
                            }
                        >
                            Calculate
                        </Button>
                    ) : (
                        <div className="spinner-container">
                            <RingLoader size={30} color="#36D7B7" loading={!showCalculateButtonC} />
                        </div>
                    )}
                </div>
                {/* Demands */}
                <div
                    className="flex-3 d-flex flex-row"
                    style={{
                        paddingInline: '3px',
                        borderRight: '1px solid black',
                        gap: 5,
                    }}
                >
                    <Form.Control
                        size="sm"
                        id="size-rainwater-tank-tool-catchment-demand-kl-day"
                        name="size-rainwater-tank-tool-catchment-demand-kl-day"
                        value={demandKlDay}
                        placeholder="kL/day"
                        onChange={(e) => handleInputChange(e.target.value, id, 'demandKlDay')}
                    />
                    <Form.Control
                        size="sm"
                        id="size-rainwater-tank-tool-demand-kl-year"
                        name="size-rainwater-tank-tool-demand-kl-year"
                        value={demandKlYear}
                        placeholder="kL/year"
                        onChange={(e) => handleInputChange(e.target.value, id, 'demandKlYear')}
                    />
                    {showCalculateButtonD ? (
                        <Button
                            type="button"
                            size="sm"
                            variant="success"
                            onClick={() => {
                                handleDemandsCalculate();
                            }}
                            disabled={
                                !catchmentArea || !reliability || !tankSize || calculatingResult
                            }
                        >
                            Calculate
                        </Button>
                    ) : (
                        <div className="spinner-container">
                            <RingLoader size={30} color="#36D7B7" loading={!showCalculateButtonD} />
                        </div>
                    )}
                </div>
                {/* Reliability */}
                <div
                    className="flex-2 d-flex flex-row"
                    style={{
                        paddingInline: '3px',
                        borderRight: '1px solid black',
                        gap: 5,
                    }}
                >
                    <Form.Control
                        size="sm"
                        id="size-rainwater-tank-tool-reliability"
                        name="size-rainwater-tank-tool-reliability"
                        value={reliability}
                        placeholder="%"
                        onChange={(e) => {
                            if (e.target.value >= 0 && e.target.value <= 100)
                                handleInputChange(e.target.value, id, 'reliability');
                            else addNotification('The reliability should be in range 0 to 100.');
                        }}
                    />
                    {showCalculateButtonR ? (
                        <Button
                            type="button"
                            size="sm"
                            variant="success"
                            onClick={() => {
                                handleReliabilityCalculate();
                            }}
                            disabled={
                                (!demandKlDay && !demandKlYear) ||
                                !catchmentArea ||
                                !tankSize ||
                                calculatingResult
                            }
                        >
                            Calculate
                        </Button>
                    ) : (
                        <div className="spinner-container">
                            <RingLoader size={30} color="#36D7B7" loading={!showCalculateButtonR} />
                        </div>
                    )}
                </div>
                {/* Tank Size */}
                <div className="flex-3 d-flex flex-row" style={{ paddingInline: '3px', gap: 5 }}>
                    <Form.Control
                        size="sm"
                        id="size-rainwater-tank-tool-tank-size"
                        name="size-rainwater-tank-tool-tank-size"
                        value={tankSize}
                        placeholder="Litres"
                        onChange={(e) => handleInputChange(e.target.value, id, 'tankSize')}
                    />
                    {showCalculateButtonT ? (
                        <Button
                            type="button"
                            size="sm"
                            variant="success"
                            onClick={() => {
                                handleTankSizeCalculate();
                            }}
                            disabled={
                                (!demandKlDay && !demandKlYear) ||
                                !reliability ||
                                !catchmentArea ||
                                calculatingResult
                            }
                        >
                            Calculate
                        </Button>
                    ) : (
                        <div className="spinner-container">
                            <RingLoader size={30} color="#36D7B7" loading={!showCalculateButtonT} />
                        </div>
                    )}
                </div>
                {/* Delete button */}
                <span
                    className="flex-1 w-100"
                    style={
                        numOfTanks > 1
                            ? {
                                  cursor: 'pointer',
                                  fontSize: '1rem',
                              }
                            : {
                                  fontSize: '1rem',
                              }
                    }
                    variant="primary"
                    onClick={() => {
                        if (numOfTanks > 1 || !calculatingResult) {
                            handleClickDeleteButton(id);
                        }
                    }}
                >
                    X
                </span>
            </div>

            {/* Notifications */}
            <div className="notification-container" style={{ gap: 2 }}>
                {notifications.map((n) => (
                    <RainwaterTankNotification
                        key={n.id}
                        message={n.message}
                        onClose={() => removeNotification(n.id)}
                        style={{ height: '26.23px' }}
                    />
                ))}
            </div>
        </div>
    );
}
