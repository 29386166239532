// component
import PaymentButton from '../PaymentButton/PaymentButton';
// modules
import React, { useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
// utils
import { selectAreas } from '../../utils/redux/WSUD/areaSlice';
import { selectSiteDetails } from '../../utils/redux/WSUD/siteDetailsSlice';
import { selectWSUDResults } from '../../utils/redux/WSUD/wsudResultsSlice';
import {
    emailLiteWsudReport,
    emailMSF,
    emailWsudReport,
    handleDownloadMLB,
    handleGoToJCO,
} from '../../utils/DownloadFunctions';
import { selectReportNoteSection } from '../../utils/redux/reportNoteSectionSlice';
import { selectProjectInfo } from '../../utils/redux/projectInfoSlice';
import { saveNewSUModel } from '../../utils/api/lambda';

export default function WSUDButtons({ userInfo, mapInfo, wsudReady, targetReductions }) {
    const [reportSent, setReportSent] = useState('');
    const areas = useSelector(selectAreas);
    const siteDetails = useSelector(selectSiteDetails);
    const wsudResults = useSelector(selectWSUDResults);
    const projectInfo = useSelector(selectProjectInfo);
    const reportNoteSection = useSelector(selectReportNoteSection);

    useEffect(() => {
        if (reportSent !== '') {
            setTimeout(() => setReportSent(''), 10000);
        }
    });

    return (
        <>
            {wsudReady && (
                <>
                    <div
                        className="d-flex flex-wrap justify-content-center align-items-center"
                        style={{ gap: 5, margin: '0 10vw' }}
                    >
                        {/* Save Model Button */}
                        <PaymentButton
                            assessorEmail={userInfo.assessorEmail}
                            serviceID={-1}
                            userID={userInfo.userID}
                            buttonTitle={'📃 Save Model'}
                            onPaymentComplete={() =>
                                saveNewSUModel(
                                    mapInfo.projectID,
                                    siteDetails,
                                    areas['impervious'],
                                    areas['pervious'],
                                    'Default Save',
                                    projectInfo,
                                    false,
                                    userInfo.assessorEmail
                                )
                                    .then((response) => {
                                        if (response[0]?.model_id)
                                            alert('Project Saved Successfully!');
                                    })
                                    .catch(() =>
                                        alert('Encountered an unexpected error while saving model'),
                                    )
                            }
                            buttonColor="#4caf50"
                        />
                        {/* WSUD Report Button */}
                        <PaymentButton
                            assessorEmail={userInfo.assessorEmail}
                            serviceID={7}
                            userID={userInfo.userID}
                            buttonTitle={'📃 WSUD Report'}
                            onPaymentComplete={() =>
                                emailWsudReport(
                                    userInfo,
                                    mapInfo,
                                    projectInfo,
                                    areas,
                                    siteDetails,
                                    wsudResults,
                                    targetReductions,
                                    siteDetails.catchmentType,
                                    reportNoteSection,
                                ).then(() => setReportSent('StormUpdated'))
                            }
                            allowLogoUpload={false}
                            buttonColor="#fc6f03"
                            subscriptionType="su"
                        />

                        {/* generate simple report button */}
                        <PaymentButton
                            assessorEmail={userInfo.assessorEmail}
                            serviceID={6}
                            userID={userInfo.userID}
                            buttonTitle={'📃 Lite WSUD Report'}
                            onPaymentComplete={() =>
                                emailLiteWsudReport(
                                    userInfo,
                                    mapInfo,
                                    projectInfo,
                                    areas,
                                    siteDetails,
                                    wsudResults,
                                    targetReductions,
                                ).then(() => setReportSent('StormUpdated Lite'))
                            }
                            buttonColor="#e91e63"
                            subscriptionType="su"
                        />

                        {/* generate MUSIC model button */}
                        <PaymentButton
                            assessorEmail={userInfo.assessorEmail}
                            serviceID={-1}
                            userID={userInfo.userID}
                            buttonTitle={'📃 Generate Music Model'}
                            onPaymentComplete={() =>
                                emailMSF(userInfo, mapInfo, projectInfo, areas, siteDetails).then(
                                    () => setReportSent('MSF'),
                                )
                            }
                            buttonColor="#2196f3"
                        />
                        {/* Go To JCO */}

                        {/* <PaymentButton
                            assessorEmail={userInfo.assessorEmail}
                            serviceID={-1}
                            userID={userInfo.userID}
                            buttonTitle={'📃 GO To JCO'}
                            onPaymentComplete={() =>
                                handleGoToJCO(
                                    userInfo,
                                    projectInfo,
                                    mapInfo,
                                    areas,
                                    siteDetails,
                                    siteDetails.catchmentType,
                                )
                            }
                            buttonColor="#4caf50"
                        /> */}

                        {/* download Meteo Template button */}
                        <PaymentButton
                            assessorEmail={userInfo.assessorEmail}
                            serviceID={5}
                            userID={userInfo.userID}
                            buttonTitle={'📃 MLB File'}
                            onPaymentComplete={() => handleDownloadMLB(mapInfo.rainfallStation)}
                            buttonColor="#9c27b0"
                            subscriptionType="su"
                        />
                    </div>
                    {/* Alert Messages */}
                    <div className="mt-3">
                        <Alert variant="primary" show={reportSent !== ''}>
                            Done! Your {reportSent} Report has been sent to:
                            <b>{' ' + userInfo.assessorEmail}</b>
                        </Alert>
                    </div>
                </>
            )}
        </>
    );
}
