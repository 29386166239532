import React from 'react';
import ReactDOM from 'react-dom';
import { Dropdown, Button, Row, Col } from 'react-bootstrap';
import ChecklistTable from './ChecklistTable';

function NonCompliantCheck({ nonCompliantNodes, compliantNodes }) {
    const hasNonCompliantNodes = nonCompliantNodes && nonCompliantNodes.length > 0;
    const hasCompliantNodes = compliantNodes && compliantNodes.length > 0;

    return (
        <div className="mx-3">
            {hasNonCompliantNodes && (
                <>
                    <h3 style={{ marginBottom: '2%' }}>Non Compliant Nodes</h3>
                    <Row>
                        {nonCompliantNodes.map((node, index) => (
                            <NonCompliantNode nodeProperties={node} key={'non-compliant' + index} />
                        ))}
                    </Row>
                </>
            )}
            {hasCompliantNodes && (
                <>
                    <div
                        style={{
                            marginBottom: '2%',
                            borderTop: hasNonCompliantNodes ? '1px solid black' : '',
                            marginTop: '3%',
                        }}
                    >
                        <h3 style={{ marginTop: '2%' }}>Compliant Nodes</h3>
                    </div>
                    <Row style={{ marginBottom: '2%', alignItems: 'flex-start' }}>
                        {compliantNodes.map((node, index) => (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'flex-start',
                                    border: '1px solid black',
                                    width: '33.333333%',
                                    padding: '1rem',
                                    gap: '1vw',
                                }}
                                key={'compliant' + index}
                            >
                                <p style={{ fontSize: '1rem', textAlign: 'left' }}>
                                    {node.nodeName}
                                </p>
                            </div>
                        ))}
                    </Row>
                </>
            )}
        </div>
    );
}

function NonCompliantNode(props) {
    const { nodeProperties } = props;

    if (!nodeProperties) {
        return <p>Error: nodeProperties is undefined</p>;
    }

    const handleViewChecklists = (template) => {
        openNewWindowWithReactComponent(template);
    };

    const openNewWindowWithReactComponent = (template) => {
        const newWindow = window.open('', '_blank', 'width=800,height=600');
        newWindow.document.write('<div id="root"></div>');
        newWindow.document.title = `Auditor: Compare ${
            nodeProperties.rows.find((row) => row.rowName === 'Node Name').rowValue
        } to guidelines for ${template.match_name}`;
        newWindow.document.close();

        const rootElement = newWindow.document.getElementById('root');

        const handleClose = () => {
            newWindow.close();
        };

        const renderComponent = () => {
            ReactDOM.render(
                <ChecklistTable
                    template={template}
                    rows={nodeProperties.rows}
                    onClose={handleClose}
                />,
                rootElement,
            );
        };

        if (newWindow.document.readyState === 'complete') {
            renderComponent();
        } else {
            newWindow.onload = renderComponent;
        }
    };

    const divStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        border: '1px solid black',
        width: '33.333333%',
        padding: '1rem',
        gap: '1vw',
    };

    return (
        <div style={divStyle}>
            <p style={{ fontSize: '1rem', textAlign: 'left' }}>{nodeProperties.nodeName}</p>
            <Dropdown className="ms-auto">
                {/* Add ms-auto to align to the right */}
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Audit against
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {nodeProperties.nonCompliantTemplates.map((template, index) => (
                        <Dropdown.Item key={index}>
                            {template.match_name}
                            <Button variant="link" onClick={() => handleViewChecklists(template)}>
                                View Checklists
                            </Button>
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}

export default NonCompliantCheck;
