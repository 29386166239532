import React from 'react';
import { StyleSheet, Document, Page, View, pdf, Image, Text } from '@react-pdf/renderer';
// components
import BasicInformation from '../BasicInformation';
import BoydsReportTemplate from './BoydsReportTemplate';
import SpecImagePages from '../SpecImagePages';
import ReportHeader from '../ReportHeader';
import ReportFooter from '../ReportFooter';
import Table from '../Table';
import BoydsMethodCalculationTemplate from './BoydsMethodCalculationTemplate';

const getBoydsReport = (data, manufactureImageLinks, manufactureDocuments) => {
    const styles = StyleSheet.create({
        page: {
            paddingTop: 30,
            paddingBottom: 10,
            paddingHorizontal: 35,
        },
        imageContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
        },
        image: {
            paddingTop: 30,
            height: 350,
            width: 400,
        },
        mainTitle: {
            fontSize: 18,
            fontweight: 700,
            textAlign: 'center',
            marginTop: 5,
            marginBottom: 20,
        },
    });
    const defaultStyles = {
        borderStyle: '1px solid #658cbb',
        textAlignment: 'center',
        headerBorder: true,
        fontSize: 9,
        headerFontSize: 9,
        headerTextAlign: 'center',
        tableWidth: '100%',
    };
    const createStyles = (newStyles = defaultStyles) => {
        return {
            section: { marginTop: 15 },
            table: {
                display: 'table',
                width: newStyles.tableWidth,
                border: newStyles.borderStyle,
                borderBottom: 0,
                borderRight: 0,
                borderLeft: 0,
                borderTop: newStyles.headerBorder ? newStyles.borderStyle : 0,
            },

            tableRow: {
                flexDirection: 'row',
                borderBottom: newStyles.borderStyle,
            },

            headerCell: {
                fontSize: newStyles.headerFontSize,
                textAlign: newStyles.headerTextAlign,
                borderRight: newStyles.headerBorder ? newStyles.borderStyle : 0,
                borderLeft: newStyles.headerBorder ? newStyles.borderStyle : 0,
                height: '100%',
                padding: 5,
            },

            tableCell: {
                fontSize: newStyles.fontSize,
                textAlign: newStyles.textAlignment,
                borderRight: newStyles.borderStyle,
                borderLeft: newStyles.borderStyle,
                height: '100%',
                padding: 5,
            },
        };
    };

    return (
        <Document>
            <BasicInformation
                reportHeaderUrl={data.reportHeaderIconUrl}
                address={{
                    suburb: data.suburb,
                    state: data.state,
                    postcode: data.postcode,
                    council: data.council,
                    address: data.address,
                    coordinate: data.coordinate,
                }}
                basicInfo={{
                    developmentType: data.developmentType,
                    permit: data.planningPermitNo,
                    projectName: 'N/A',
                    assessor: data.assessorName,
                    email: data.assessorEmail,
                }}
            />

            {/* OSD Summary*/}
            <BoydsReportTemplate data={data} />

            {data.osd.method?.toLowerCase() === 'boyds method' && (
                <BoydsMethodCalculationTemplate data={data} />
            )}

            {/* Images */}
            <SpecImagePages
                osdCompany={data.OSDCompany}
                manufactureImageLinks={manufactureImageLinks}
                manufactureDocuments={manufactureDocuments}
                reportHeaderUrl={data.reportHeaderIconUrl}
            />

            {data.osd.tDurChart && data.osd.tDurChart !== '' && (
                <>
                    {/* Storm Duration Chart */}
                    <Page style={styles.page} size="A4">
                        <View>
                            <ReportHeader reportHeaderUrl={data.reportHeaderIconUrl} />
                        </View>
                        <View>
                            <Text style={styles.mainTitle}>
                                Range of Durations used to Determine Max volume Required
                            </Text>
                        </View>
                        <View style={styles.imageContainer}>
                            <Image src={data.osd.tDurChart} style={styles.image} />
                        </View>
                        <ReportFooter />
                    </Page>

                    {/* Storm Duration Table */}
                    <Page style={styles.page} size="A4" wrap={false}>
                        <View>
                            <ReportHeader reportHeaderUrl={data.reportHeaderIconUrl} />
                        </View>
                        <View>
                            <Text style={styles.mainTitle}>
                                Range of Durations Calculated {'\n'}to Determine Critical Duration
                            </Text>
                        </View>
                        <Table
                            columnNames={[
                                'Td, min',
                                'I, mm/hr',
                                'Qpre, L/s',
                                'Quncontrolled, L/s',
                                'Qpeak, L/s',
                                'Vreq, (m3)',
                            ]}
                            tableData={data.osdResult.report.vreq_n_data.map((item) => {
                                const intenSityTDur = +item.intensity_tdur;
                                const qPostTN = +item.q_post_tn;
                                const vReqN = +item.Vreq_n;
                                const qPreTN = +item.q_pre_adjusted;
                                const qUncontrolled = +item.q_uncontrolled_tn;
                                if (+item.t_dur > 0)
                                    return [
                                        item.t_dur,
                                        intenSityTDur.toFixed(4),
                                        qPreTN.toFixed(4),
                                        qUncontrolled.toFixed(4),
                                        qPostTN.toFixed(4),
                                        vReqN.toFixed(4),
                                    ];
                                else return null;
                            })}
                            cellWidths={['16.66%', '16.66%', '16.66%', '16.66%', '16.66%', '16.66%',]}
                            tableStyles={createStyles()}
                        />
                        <ReportFooter />
                    </Page>
                </>
            )}
        </Document>
    );
};

// main function
export const generateBoydsReport = async (data, manufactureImageLinks, manufactureDocuments) => {
    try {
        const reportBlob = await pdf(
            getBoydsReport(data, manufactureImageLinks, manufactureDocuments),
        ).toBlob();
        return reportBlob;
    } catch (err) {
        console.error(err);
    }
};
