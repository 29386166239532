// NotificationComponent.js

import React, { useEffect } from 'react';

export const RainwaterTankNotification = ({ message, onClose, autoDismissTimeout = 5000 }) => {
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            onClose();
        }, autoDismissTimeout);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [onClose, autoDismissTimeout]);

    return (
        <div className="RainwaterTankNotification">
            <p>{message}</p>
        </div>
    );
};

export const handleNotification = (
    id,
    message,
    notifications,
    setNotifications,
    setShowCalculateButtonC,
    setShowCalculateButtonR,
    setShowCalculateButtonD,
    setShowCalculateButtonT,
) => {
    setShowCalculateButtonC(true);
    setShowCalculateButtonR(true);
    setShowCalculateButtonD(true);
    setShowCalculateButtonT(true);

    const existingNotificationIndex = notifications.findIndex((n) => n.id === id);

    const newNotification = { id, message };

    if (existingNotificationIndex !== -1) {
        const updatedNotifications = [...notifications];
        updatedNotifications[existingNotificationIndex] = newNotification;
        setNotifications(updatedNotifications);
    } else {
        setNotifications((prevNotifications) => [newNotification, ...prevNotifications]);
    }

    if (existingNotificationIndex !== -1) {
        const existingTimeoutId = notifications[existingNotificationIndex].timeoutId;
        clearTimeout(existingTimeoutId);
    }

    const timeoutId = setTimeout(() => {
        clearNotification(id, notifications, setNotifications);
    }, 5000);

    newNotification.timeoutId = timeoutId;
};

export const clearNotification = (id, notifications, setNotifications) => {
    setNotifications((prevNotifications) =>
        prevNotifications.filter((notification) => notification.id !== id),
    );
};
