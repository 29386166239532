import React from 'react';
import { StyleSheet, View, Page, Text } from '@react-pdf/renderer';

import ReportHeader from '../ReportHeader';
import Table from '../Table';
import ReportFooter from '../ReportFooter';
import { TCCalculatorTemplate } from '../tc-calculation/TCCalculatorTemplate';

// using https://lingojam.com/SuperscriptGenerator to generate superscripts (<sup>2</sup> is not accept by React PDF)

function round(num) {
    return check(
        parseFloat(num)
            .toFixed(2)
            .replace(/\.?0+$/, ''),
    );
}

function check(num) {
    if (num === undefined || num === null || isNaN(num) | (num === '')) return 'N/A';
    return num;
}

const getClimatePercentage = (num) => {
    if (num === 1) {
        return 'No climate change adjustment';
    } else if (num < 1) {
        return '-' + round((1 - num) * 100) + '%';
    } else {
        return '+' + round((num - 1) * 100) + '%';
    }
};

function getAdjustedStorageVolume(num, adjustment) {
    return round(num * adjustment);
}

function formatObjectName(name) {
    if (name.toLowerCase().includes('area')) return name + ' (m²)';
    if (name.toLowerCase().includes('volume')) return name + ' (m³)';
    // if (name.toLowerCase().includes('psd')) return name + ' *';
    return name;
}

const defaultStyles = {
    borderStyle: '1px solid #658cbb',
    textAlignment: 'center',
    headerBorder: true,
    fontSize: 9,
    headerFontSize: 9,
    headerTextAlign: 'center',
    tableWidth: '100%',
    marginTop: 15,
    headerTextDecoration: 'none',
};

const createStyles = (newStyles = defaultStyles) => {
    return {
        section: { marginTop: newStyles.marginTop },
        table: {
            display: 'table',
            width: newStyles.tableWidth,
            border: newStyles.borderStyle,
            borderBottom: 0,
            borderRight: 0,
            borderLeft: 0,
            borderTop: newStyles.headerBorder ? newStyles.borderStyle : 0,
        },

        tableRow: {
            flexDirection: 'row',
            borderBottom: newStyles.borderStyle,
        },

        headerCell: {
            fontSize: newStyles.headerFontSize,
            textAlign: newStyles.headerTextAlign,
            borderRight: newStyles.headerBorder ? newStyles.borderStyle : 0,
            borderLeft: newStyles.headerBorder ? newStyles.borderStyle : 0,
            height: '100%',
            padding: 5,
            textDecoration: newStyles.headerTextDecoration,
        },

        tableCell: {
            fontSize: newStyles.fontSize,
            textAlign: newStyles.textAlignment,
            borderRight: newStyles.borderStyle,
            borderLeft: newStyles.borderStyle,
            height: '100%',
            padding: 5,
        },
    };
};

const generateAllTableData = (data) => {
    return [
        // !data.osd.psd
        //     ? {
        //           columns: [
        //               'Pre-Development Site Details',
        //               'Area (m²)',
        //               'Controlled Area (m²)',
        //               'Uncontrolled Area (m²)',
        //           ],
        //           items: data.osd.preDevAreas.areas.map((obj) => {
        //               return [obj.areaName, obj.surfaceArea, 'N/A', 'N/A'];
        //           }),
        //           styles: createStyles(),
        //           cellWidths: ['35%', '20%', '20%', '25%'],
        //       }
        //     : {},
        //
        // {
        //     columns: [
        //         'Post-Development Site Details',
        //         'Area (m²)',
        //         'Controlled Area (m²)',
        //         'Uncontrolled Area (m²)',
        //     ],
        //     items: data.osd.postDevAreas.areas.map((obj) => {
        //         return [
        //             obj.areaName,
        //             obj.surfaceArea,
        //             obj.surfaceArea - obj.uncontrolled,
        //             obj.uncontrolled,
        //         ];
        //     }),
        //     styles: createStyles(),
        //     cellWidths: ['35%', '20%', '20%', '25%'],
        // },

        data.osd.psd
            ? {
                  columns: ['', 'Pre-Development', 'Post-Development'],
                  items: data.osdResult.report.site_calcs.map((obj) => {
                      if (obj.double_cols || obj.triple_cols) {
                          return [obj.name, round(obj.value)];
                      }

                      return [formatObjectName(obj.name), 'N/A', round(obj.post_dev)];
                  }),
                  cellWidths: ['44%', '28%', '28%'],
                  customCellWidths: data.osdResult.report.site_calcs.reduce(
                      (result, obj, index) => {
                          if (obj.double_cols || obj.triple_cols) {
                              result[index] = ['44%', '56%'];
                          }
                          return result;
                      },
                      {},
                  ),
                  styles: createStyles(),
              }
            : {
                  columns: [
                      '',
                      'Pre-Development',
                      'Post-Development Controlled',
                      'Post-Development Uncontrolled',
                  ],

                  // using array copy as it is changed during flatMap
                  items: [...data.osdResult.report.site_calcs].flatMap((obj, index) => {
                      if (obj.double_cols || obj.triple_cols) {
                          return [[obj.name, round(obj.value)]];
                      }

                      /* Slope */
                      if (obj.name.toLowerCase().includes('slope')) {
                          return [
                              [
                                  formatObjectName(obj.name),
                                  data.osd.hasTcPre ? 'N/A' : round(obj.pre_dev),
                                  data.osd.hasTcPost ? 'N/A' : round(obj.post_dev),
                                  data.osd.hasTcU ? 'N/A' : round(obj.uncontrolled),
                              ],
                          ];
                      }

                      /* Length of Flow Path */
                      if (obj.name.toLowerCase().includes('length of flow')) {
                          return [
                              [
                                  formatObjectName(obj.name),
                                  data.osd.hasTcPre ? 'N/A' : round(obj.pre_dev),
                                  data.osd.hasTcPost ? 'N/A' : round(obj.post_dev),
                                  data.osd.hasTcU ? 'N/A' : round(obj.uncontrolled),
                              ],
                          ];
                      }

                      /* TC */
                      if (obj.name.toLowerCase().includes('tc')) {
                          // Returning two objects in this case, so adding dummy value to data
                          // to make sure customWidths render correctly.
                          // Assumes items is declared before customCellWidths in JS
                          data.osdResult.report.site_calcs.splice(index, 0, {
                              adoptedTCrow: true,
                          });

                          return [
                              [
                                  formatObjectName(obj.name),
                                  data.osd.hasTcPre ? round(data.osd.tcPre) : round(obj.pre_dev),
                                  data.osd.hasTcPost ? round(data.osd.tcPost) : round(obj.post_dev),
                                  data.osd.hasTcU ? round(data.osd.tcU) : round(obj.uncontrolled),
                              ],
                              ['Adoped Tc Post', 'N/A', round(obj.tc_post_adopted)],
                          ];
                      }

                      return [
                          [
                              formatObjectName(obj.name),
                              round(obj.pre_dev),
                              round(obj.post_dev),
                              round(obj.uncontrolled),
                          ],
                      ];
                  }),

                  cellWidths: ['40%', '20%', '20%', '20%'],
                  customCellWidths: [...data.osdResult.report.site_calcs].reduce(
                      (result, obj, index) => {
                          if (obj.double_cols || obj.triple_cols) {
                              result[index] = ['40%', '60%'];
                          }
                          if (obj.adoptedTCrow) {
                              // removing the placeholder obj adding in during items above (see last comment ^)
                              data.osdResult.report.site_calcs.splice(index, 1);
                              result[index + 1] = ['40%', '20%', '40%'];
                          }
                          return result;
                      },
                      {},
                  ),
                  styles: createStyles(),
              },
        // Storm Details
        {
            columns: ['Storm Details'],
            items: data.osdResult.report.storm_details.map((obj) => [obj.name, round(obj.value)]),
            styles: createStyles({
                ...defaultStyles,
                headerBorder: false,
                headerFontSize: 13,
                headerTextAlign: 'left',
                tableWidth: '50%',
            }),
            cellWidths: ['70%', '30%'],
        },

        // Runoff Coefficient (Cy)
        // {
        //     columns: ['Runoff Coefficient (Cy)'],
        //     items: data.osd.psd
        //         ? [
        //               ['Pre Dev', round(data.osdResult.report.runoff_coefficient.pre_dev)],
        //               ['Post Dev', round(data.osdResult.report.runoff_coefficient.post_dev)],
        //           ]
        //         : [
        //               ['Pre Dev', round(data.osdResult.report.runoff_coefficient.pre_dev)],
        //               [
        //                   'Post Dev Controlled',
        //
        //                   round(data.osdResult.report.runoff_coefficient.post_dev.controlled),
        //               ],
        //               [
        //                   'Post Dev Uncontrolled',
        //
        //                   round(data.osdResult.report.runoff_coefficient.post_dev.uncontrolled),
        //               ],
        //           ],
        //     styles: createStyles({
        //         ...defaultStyles,
        //         headerBorder: false,
        //         headerFontSize: 13,
        //         headerTextAlign: 'left',
        //         tableWidth: '50%',
        //     }),
        //     headerWidths: ['100%'],
        //     cellWidths: ['70%', '30%'],
        // },

        data.osd.psd
            ? {}
            : cValueTable(
                  'C-Value (Pre Dev)',
                  data.osdResult.report.c_value_pre,
                  data.osdResult.intermediate_results,
                  {
                      isPreDev: true,
                      isControlled: true,
                      showAppendix: false,
                  },
              ),
        cValueTable(
            'C-Value (Post Dev Controlled)',
            data.osdResult.report.c_value_post,
            data.osdResult.intermediate_results,
            {
                isPreDev: false,
                isControlled: true,
                showAppendix: false,
            },
        ),

        cValueTable(
            'C-Value (Post Dev Uncontrolled)',
            data.osdResult.report.c_value_post,
            data.osdResult.intermediate_results,
            {
                isPreDev: false,
                isControlled: false,
                showAppendix: true,
            },
        ),

        // Flow and Volume
        {
            columns: ['Flow and Volume'],
            items: data.osdResult.report.flow_and_vol
                .map((obj) => [formatObjectName(obj.name), round(obj.value)])
                .concat([
                    data.osd.climateAdjustment !== '1'
                        ? [
                              'Climate Change (% increase of required storage volume):',
                              getClimatePercentage(parseFloat(data.osd.climateAdjustment)),
                          ]
                        : [],
                    data.osd.climateAdjustment !== '1'
                        ? [
                              'Adjusted Required Storage Volume (m³):',
                              getAdjustedStorageVolume(
                                  data.osdResult.max_volume,
                                  data.osd.climateAdjustment,
                              ),
                          ]
                        : [],
                ]),
            styles: createStyles({
                ...defaultStyles,
                headerBorder: false,
                headerFontSize: 13,
                headerTextAlign: 'left',
                tableWidth: '70%',
            }),
            cellWidths: ['70%', '30%'],
        },
        // Orifice Calculation
        data.osdResult.report.orifice.tankHeight
            ? {
                  columns: ['Orifice Calculation'],
                  items: [
                      [
                          'Height of Storage Above Orifice (m)',
                          round(data.osdResult.report.orifice.tankHeight),
                      ],
                      [
                          'Orifice Diameter (mm)',
                          round(data.osdResult.report.orifice.orificeDiameter),
                      ],
                  ],
                  cellWidths: ['70%', '30%'],
                  headerWidths: ['100%'],
                  styles: createStyles({
                      ...defaultStyles,
                      textAlignment: 'left',
                      headerBorder: false,
                      headerFontSize: 13,
                      headerTextAlign: 'left',
                      tableWidth: '45%',
                  }),
              }
            : {},
    ];
};

const BoydsReportTemplate = ({ data }) => {
    const styles = StyleSheet.create({
        body: {
            paddingTop: 30,
            paddingBottom: 30,
            paddingHorizontal: 35,
            marginBottom: 10,
        },
        section: {
            marginBottom: 10,
            padding: 15,
            display: 'flex',
            flexDirection: 'row',
        },
        mainTitle: {
            fontSize: 18,
            fontweight: 1000,
            textAlign: 'center',
            marginTop: 5,
            marginBottom: 5,
        },
        catchmentTimes: {
            marginTop: 10,
            fontSize: 15,
            width: '100%',
            justifyContent: 'end',
            display: 'flex',
        },
        disclaimer: {
            fontSize: 9,
            marginTop: 15,
            lineHeight: 1.3,
        },
    });

    return (
        <Page style={styles.body} wrap>
            <ReportHeader reportHeaderUrl={data.reportHeaderIconUrl} />
            <View style={{ pageBreakAfter: 'always' }}>
                <Text style={styles.mainTitle}>OSD Design Summary Report</Text>
                <Text style={{ fontSize: 12, marginTop: 10 }}>
                    Method for OSD calculations: {data.osd.method.toUpperCase()}
                </Text>
            </View>

            {/* OSD System Specified */}
            <View>
                <Text style={{ fontSize: 10, marginTop: 10 }}>Device Name: {data.OSDCompany}</Text>
                <Text style={{ fontSize: 10, marginTop: 10 }}>
                    Device Type: {data.OSDMaterial} - {data.storageType}
                </Text>
            </View>
            {/* ... */}

            {/* TC Calculator */}
            <TCCalculatorTemplate
                tcData={data.osd.tcData}
                createStyles={createStyles}
                defaultStyles={defaultStyles}
            />
            {/* Site Details */}
            <View style={{ marginBottom: 50 }}>
                {generateAllTableData(data).map((table, index) => (
                    <>
                        {table.styles ? (
                            <Table
                                tableKey={index}
                                columnNames={table.columns}
                                tableData={table.items}
                                headerWidths={table.headerWidths}
                                cellWidths={table.cellWidths}
                                customCellWidths={table.customCellWidths}
                                tableStyles={table.styles}
                            />
                        ) : (
                            <></>
                        )}
                        {table.appendix ?? <></>}
                    </>
                ))}
            </View>
            <ReportFooter />
        </Page>
    );
};

/**
 * @param {string} title - Table title
 * @param {object[]} cValue - c-value post dev results
 */
const cValueTable = (
    title,
    cValue,
    intermediateResults,
    { isPreDev, isControlled, showAppendix },
) => {
    const { C_10, I_10 } = intermediateResults;
    const tableHeader = [
        'Catchment Name',
        isControlled ? 'Area, m2 (controlled)' : null,
        !isPreDev && !isControlled ? 'Area, m2 (uncontrolled)' : null,
        'Total Impervious Area, m2 (b)',
        'Fraction Impervious (b)',
        'C10 (b)',
        'C-Value (a)',
        isControlled ? 'Weighted C-Value (controlled) (e)' : null,
        !isPreDev && !isControlled ? 'Weighted C-Value (uncontrolled)' : null,
    ].filter((value) => value !== null);

    const tableRows = [...cValue].flatMap((obj) => {
        // Each row
        const cyIsProvided = obj.fractionImpervious === null;
        let cySuffix = cyIsProvided ? '(d)' : '(c)';
        console.log(obj);

        return [
            [
                formatObjectName(obj.catchmentName),
                isControlled ? round(obj.areaControlled) : null,
                !isPreDev && !isControlled
                    ? typeof obj.areaUncontrolled === 'number'
                        ? round(obj.areaUncontrolled)
                        : 'N/A'
                    : null,
                obj.totalImperviousArea !== null ? round(obj.totalImperviousArea) : 'N/A',
                obj.fractionImpervious !== null ? round(obj.fractionImpervious) : 'N/A',
                obj.c10 ? round(obj.c10) : 'N/A',
                obj.cy ? `${round(obj.cy)} ${cySuffix}` : 'N/A',
                isControlled ? (obj.cyWeighted ? round(obj.cyWeighted) : 'N/A') : null,
                !isPreDev && !isControlled
                    ? typeof obj.cyWeightedUncontrolled === 'number'
                        ? round(obj.cyWeightedUncontrolled)
                        : 'N/A'
                    : null,
            ].filter((value) => value !== null),
        ];
    });

    const calculateTotalCyWeighted = (cyWeighted) =>
        cValue.reduce((acc, area) => (cyWeighted ? acc + area[cyWeighted] : acc), 0);

    let totalCyWeighted;

    if (isPreDev) totalCyWeighted = calculateTotalCyWeighted('cyWeighted');
    else if (!isPreDev && isControlled)
        totalCyWeighted = calculateTotalCyWeighted('cyWeightedControlled');
    else if (!isPreDev && !isControlled)
        totalCyWeighted = calculateTotalCyWeighted('cyWeightedUncontrolled');

    const tableFooter = [
        '',
        isControlled ? '' : null,
        !isPreDev && !isControlled ? '' : null,
        '',
        '',
        '',
        isControlled
            ? 'Total Weighted C-Value (controlled) (f)'
            : 'Total Weighted C-Value (uncontrolled)',
        round(totalCyWeighted),
    ].filter((value) => value !== null);

    const cellWidths = ['30%', '15%', '15%', '10%', '10%', '10%', '10%'];

    return {
        columns: [title],
        items: [tableHeader, ...tableRows, tableFooter],
        cellWidths,
        styles: createStyles({
            ...defaultStyles,
            headerBorder: false,
            headerFontSize: 12,
            headerTextAlign: 'left',
        }),
        appendix: showAppendix ? (
            <>
                <Text style={{ fontSize: 10, marginTop: 10 }}>Notes:</Text>
                <Text style={{ fontSize: 10, marginTop: 5 }}>
                    (a) C-Value refers to a coefficient of discharge (also known as Cy, calculated
                    using QUDM & AR&R method) or volumetric runoff coefficient (also known as Cv,
                    typically estimated by Engineer or referenced by Guidelines like IDM Victoria).
                    They are functionally similar within the rational method equation (difference is
                    the methodology used to determine these values.). It is recommended that the
                    same methodology is used for all catchments to ensure consistency.
                </Text>
                <Text style={{ fontSize: 10, marginTop: 5 }}>
                    (b) Only used in calculation of Cy values (see QUDM page)
                </Text>
                <Text style={{ fontSize: 10, marginTop: 5 }}>
                    (c) A coefficient of discharge (Cy) calculated using QUDM & AR&R method (see
                    QUDM page)
                </Text>
                <Text style={{ fontSize: 10, marginTop: 5 }}>
                    (d) C-Value specified by engineer.
                </Text>
                <Text style={{ fontSize: 10, marginTop: 5 }}>
                    (e) This is a weighted average that represents the catchment's contribution to
                    the total C-Value of the development
                </Text>
                <Text style={{ fontSize: 10, marginTop: 5 }}>
                    (f) This is the total C-Value used to represent the development in the rational
                    method calculation.
                </Text>
                <Text style={{ fontSize: 10, marginTop: 5 }}>
                    Where applicable, table below shows values required for calculation of Cy
                    values.
                </Text>
                <Table
                    columnNames={['Parameter', 'Value']}
                    tableData={[
                        ['Intensity @ 60 min duration for 10% AEP, I10', round(C_10.c_10)],
                        ['Pervious 10% AEP runoff coefficient, C10', round(I_10)],
                    ]}
                    tableStyles={createStyles({
                        ...defaultStyles,
                        tableWidth: '50%',
                    })}
                    cellWidths={['70%', '30%']}
                />
            </>
        ) : undefined,
    };
};

export default BoydsReportTemplate;
