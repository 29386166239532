// Modules
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
// Components
import MapInfo from '../MapInfo/MapInfo';
import OccupancyCalculator from './OccupancyCalculator/OccupancyCalculator';
import IndoorDemandCalculator from './IndoorDemandCalculator/IndoorDemandCalculator';
import IrrigationDemandCalculator from './IrrigationDemandCalculator/IrrigationDemandCalculator';
import RwtSizing from './RwtSizing/RwtSizing';
// Validations
import { isNullish } from '../../validations/common';
// Utils
import useFavicon from './../../utils/usdFavicon';
import { useSelector } from 'react-redux';
import { selectMapInfo } from '../../utils/redux/mapInfoSlice';
import { selectUserInfo } from '../../utils/redux/userInfoSlice';
import { selectProjectInfo } from '../../utils/redux/projectInfoSlice';
import { handleRWTDemandReport } from '../../utils/DownloadFunctions';
// API
import rwtApi from './rwtApi';
// Styles
import './RwtContainer.css';

const RwtContainer = () => {
    useFavicon('rwt-demand-tool');
    const mapInfo = useSelector(selectMapInfo);
    const userInfo = useSelector(selectUserInfo);
    const projectInfo = useSelector(selectProjectInfo);

    const formStatus = {
        initial: 'initial',
        inProgress: 'inProgress',
    };

    const [isReady, setIsReady] = useState(false);
    const [calculators, setCalculators] = useState([]);
    const [annualEvapotranspiration, setAnnualEvapotranspiration] = useState(null);

    useEffect(() => {
        rwtApi
            .getCalculators()
            .then((data) => setCalculators(data))
            .catch((error) => alert(error.message))
            .finally(() => setIsReady(true));
        rwtApi.getAnnualET(mapInfo.coordinate.lat, mapInfo.coordinate.lng).then((data) => {
            setAnnualEvapotranspiration(data);
        });
    }, [mapInfo]);

    //-------------------------------------------
    // --> Calculator state
    //-------------------------------------------

    const [occupancyCalcState, setOccupancyCalcState] = useState({
        selectedMethod: undefined,
        fields: {},
        errors: {},
        result: undefined,
    });

    const [indoorCalcState, setIndoorCalcState] = useState({
        selectedMethod: undefined,
        fields: {},
        errors: {},
        result: undefined,
    });

    const [irrigationCalcState, setIrrigationCalcState] = useState({
        selectedMethod: undefined,
        fields: {},
        errors: {},
        result: undefined,
    });

    const [rwtSizingState, setRwtTankSizingState] = useState([]);

    const showReportGenButton =
        !isNullish(occupancyCalcState.result) ||
        !isNullish(indoorCalcState.result) ||
        !isNullish(irrigationCalcState.result) ||
        rwtSizingState.length > 0;

    const onMethodChange = (method, calculatorState, setCalculatorState) => {
        // Create a copy of the state
        const formStateUpdated = { ...calculatorState };

        // Modify state
        formStateUpdated.selectedMethod = { ...method, fields: undefined } ?? undefined;
        formStateUpdated.fields = {};
        formStateUpdated.errors = {};
        formStateUpdated.result = undefined;
        formStateUpdated.status = formStatus.initial;

        // Init fields
        method?.fields?.forEach((field) => {
            formStateUpdated.fields[field.id] = { ...field, value: field.defaultValue ?? '' };

            // Init subfields
            if (field?.subfields?.length > 0) {
                formStateUpdated.fields[field.id].subfields = {};
                field.subfields.forEach((subfield) => {
                    formStateUpdated.fields[field.id].subfields[subfield.id] = {
                        ...subfield,
                        value: [subfield.defaultValue ?? ''],
                    };
                });
            } else {
                // Delete subfield property if the field doesn't have subfields
                delete formStateUpdated.fields[field.id].subfields;
            }
        });

        // Update state
        setCalculatorState(formStateUpdated);
    };

    const formatRwtSizingState = (state) => {
        if (state?.length === 0) {
            return {
                selectedMethod: undefined,
                fields: {},
                errors: {},
                result: undefined,
            };
        }

        const result = {
            selectedMethod: { id: null, label: null },
            fields: {
                calculations: {
                    subfields: {
                        catchmentArea: {
                            id: 'catchmentArea',
                            label: 'Catchment Area',
                            value: [],
                        },
                        occupantDemand: {
                            id: 'occupantDemand',
                            label: 'Occupant Demand (kL/day)',
                            value: [],
                        },
                        irrigationDemand: {
                            id: 'irrigationDemand',
                            label: 'Irrigation Demand (kL/year)',
                            value: [],
                        },
                        reliability: {
                            id: 'reliability',
                            label: 'Reliability (%)',
                            value: [],
                        },
                        tankSize: {
                            id: 'tankSize',
                            label: 'Tank Size (L)',
                            value: [],
                        },
                    },
                },
            },
            result: '',
        };

        [...state].forEach((row) => {
            result.fields.calculations.subfields.catchmentArea.value.push(row.catchmentArea);
            result.fields.calculations.subfields.occupantDemand.value.push(row.demandKlDay);
            result.fields.calculations.subfields.irrigationDemand.value.push(row.demandKlYear);
            result.fields.calculations.subfields.reliability.value.push(row.reliability);
            result.fields.calculations.subfields.tankSize.value.push(row.tankSize);
        });

        return result;
    };

    const generateReport = () => {
        const results = {};
        [
            { id: 'occupancyCalcState', state: occupancyCalcState },
            { id: 'indoorCalcState', state: indoorCalcState },
            { id: 'irrigationCalcState', state: irrigationCalcState },
            { id: 'rwtSizingState', state: formatRwtSizingState(rwtSizingState) },
        ].forEach(({ id, state }) => {
            results[id] = {
                method: state.selectedMethod,
                fields: state.fields,
                result: state.result,
            };
        });

        handleRWTDemandReport(results, userInfo, mapInfo, projectInfo);
        rwtApi.logReportGeneration({ userEmail: userInfo.assessorEmail });
    };

    return (
        <div className="rwt-calculator">
            <MapInfo mapInfo={mapInfo} />
            <OccupancyCalculator
                isReady={isReady}
                methods={calculators.occupancyDemandCalculator?.methods}
                onMethodChange={(method) =>
                    onMethodChange(method, occupancyCalcState, setOccupancyCalcState)
                }
                calculatorState={occupancyCalcState}
                setCalculatorState={setOccupancyCalcState}
            />
            <IndoorDemandCalculator
                isReady={isReady}
                methods={calculators.indoorDemandCalculator?.methods}
                onMethodChange={(method) =>
                    onMethodChange(method, indoorCalcState, setIndoorCalcState)
                }
                calculatorState={indoorCalcState}
                setCalculatorState={setIndoorCalcState}
            />
            <IrrigationDemandCalculator
                isReady={isReady}
                methods={calculators.irrigationDemandCalculator?.methods}
                onMethodChange={(method) =>
                    onMethodChange(method, irrigationCalcState, setIrrigationCalcState)
                }
                annualEvapotranspiration={annualEvapotranspiration}
                calculatorState={irrigationCalcState}
                setCalculatorState={setIrrigationCalcState}
            />
            <RwtSizing
                isReady={isReady}
                methods={calculators.rwtSizingCalculator?.methods}
                // calculatorState={rwtSizingState}
                setRwtTankSizingState={setRwtTankSizingState}
            />
            {showReportGenButton && (
                <div style={{ padding: '1rem', textAlign: 'center' }}>
                    <Button variant="orange" onClick={generateReport}>
                        Generate Report
                    </Button>
                </div>
            )}
        </div>
    );
};

export default RwtContainer;
