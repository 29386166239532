// WSUDForm.jsx

import React, { useEffect, useState } from 'react';
import axios from 'axios';

import styles from './WSUDForm.module.css';
import SiteDetailsForm from './Site Details/SiteDetailsForm';
import AreaForm from './AreaForm/AreaForm';
import ContextProvider from '../../../utils/contextProvider';
import SummarySection from './SummarySection/SummarySection';
import OverallResults from './OverallResultsSections.js/OverallResults';
import { selectAreas, setUpdateAllAreaResults } from '../../../utils/redux/WSUD/areaSlice';
import { useDispatch, useSelector } from 'react-redux';
import { sails_api } from '../../../utils/globalConstant';
import {
    selectSiteDetails,
    setEOLResultUpdateRequired,
} from '../../../utils/redux/WSUD/siteDetailsSlice';
import { setEOLResult } from '../../../utils/redux/WSUD/siteDetailsSlice';
import { setWSUDResults } from '../../../utils/redux/WSUD/wsudResultsSlice';
import { QueryClient, QueryClientProvider } from 'react-query';

/* Quick Start Guide
 *
 * WSUD Form Structure:
 * All Impervious / Pervious Area Form inputs and results are stored in areaSlice
 * Similarly EOL Device inputs  and results are stored in siteDetailsSlice.
 * Overall Results are stored in the wsudResultsSlice.
 *
 * Result Updates:
 * When Area inputs are changed, area.resultUpdateRequired is set to true.
 * This triggers a (re-)calculation of area results (for that row).
 * When any of the area results are updated, areaResultsUpToDate is set to null
 * This triggers handleAreaResultsChanged, which recalculates areaResultsUpToDate value
 * If the new value is true, handleUpdateOverallResults is triggered, which (re-)calculates
 * the Overall Results.
 *
 * Other Events:
 * When EOL Device is updated, the treatmentDevice for each row may need to be updated.
 * For each area, if the selected treatmentDevice is available under the new EOL Device,
 * nothing changes. Otherwise the treatmentDevice is set to None.
 *
 */

const queryClient = new QueryClient();

const WSUDForm = ({
    eolTreatments,
    rainfallStation,
    state,
    cityCouncil,
    targetReductions,
    setWsudReady,
}) => {
    const dispatch = useDispatch();
    const areas = useSelector(selectAreas);
    const siteDetails = useSelector(selectSiteDetails);

    const [areaResultsUpToDate, setAreaResultsUpToDate] = useState(false);

    const mapInfo = {
        rainfallStation,
        catchmentType: siteDetails.catchmentType,
        cityCouncil,
        state,
    };

    // when rainwaterDemand or EOL device is changed, update all Area Results
    // this will automatically update Overall Results once Area Results are updated
    useEffect(() => {
        dispatch(setUpdateAllAreaResults(true));
        dispatch(setEOLResultUpdateRequired(true)); // hide EOL Results
    }, [siteDetails.rainwaterDemand, siteDetails.treatmentDevice]);

    // when EOL device input is changed, update OverallResults
    useEffect(() => {
        const timer = setTimeout(() => handleUpdateOverallResults(), 2000);
        return () => clearTimeout(timer);
    }, [siteDetails.deviceInput]);

    // each time any Area Results are updated, areaResultsUpToDate is set to null
    // if areaResultsUpToDate is null, updates its value to either true or false
    useEffect(() => {
        if (areaResultsUpToDate === null) handleAreaResultsChanged();
        else if (areaResultsUpToDate) {
            const cancelTokenSource = axios.CancelToken.source();
            handleUpdateOverallResults(cancelTokenSource.token);
            return () => {
                cancelTokenSource.cancel('Component unmounted or areas changed.');
            };
        }
    }, [areaResultsUpToDate]);

    const handleAreaResultsChanged = () => {
        setAreaResultsUpToDate(
            areas['pervious'].every((item) => !item.resultUpdateRequired) &&
                areas['impervious'].every((item) => !item.resultUpdateRequired),
        );
    };

    const handleUpdateOverallResults = () => {
        setWsudReady(false);
        const extractResults = (area) => {
            return area.map((node, index) => {
                if (node.result === undefined || node.result.alert !== undefined)
                    throw Error(`Please check the inputs for row ${index + 1}`);
                return { results: node.result };
            });
        };

        const formatEolResults = (eolResults) => {
            const formatEolResult = (eolResult, targetReduction) =>
                ((parseFloat(eolResult) / targetReduction) * 100).toFixed(1);
            return {
                tn: formatEolResult(eolResults.reductionPercTN, targetReductions.tn),
                tp: formatEolResult(eolResults.reductionPercTP, targetReductions.tp),
                tss: formatEolResult(eolResults.reductionPercTSS, targetReductions.tss),

                gp: formatEolResult(eolResults.reductionPercGP, targetReductions.gp),
            };
        };
        try {
            let payload = {
                targetReductions,
                imperviousNodes: extractResults(areas['impervious']),
                perviousNodes: extractResults(areas['pervious']),
                pervious: areas['pervious'].length > 0,
                state,
                // add eol data if inputted
                ...(siteDetails.treatmentDevice !== '' && siteDetails.deviceInput !== ''
                    ? {
                          eolDeviceName: siteDetails.treatmentDevice,
                          eolTreatSize: siteDetails.deviceInput,
                          mtemplate: rainfallStation,
                          wqMethod: 'EOLStandard',
                      }
                    : { wqMethod: 'Standard' }),
            };

            const response = sails_api.post('/OverallResults', payload);
            response
                .then((res) => {
                    if (res.data.overallRatings?.tpRate === 'NaN') {
                        throw Error('Invalid Results Received');
                    }
                    dispatch(setWSUDResults(res.data));
                    if (res.data.eolResults !== undefined) {
                        dispatch(setEOLResult(formatEolResults(res.data.eolResults)));
                    } else {
                        dispatch(setEOLResult(null));
                    }
                    setWsudReady(true);
                })
                .catch(() => {
                    handleRemoveOverallResults();
                });
        } catch (err) {
            handleRemoveOverallResults();
        }
    };

    const handleRemoveOverallResults = () => {
        dispatch(setEOLResult(null));
        dispatch(setWSUDResults(null));
        setWsudReady(false);
    };

    return (
        <QueryClientProvider client={queryClient}>
            <div className={styles['wsud-form']}>
                <div className={styles['form-header']}>
                    <label className={styles['checkbox-label']} htmlFor="openFormCheckbox">
                        Water Quality
                    </label>
                </div>
                <div className="d=flex justify-content-center w-100">
                    <div>
                        <SiteDetailsForm
                            eolTreatments={eolTreatments}
                            resultsUpToDate={areaResultsUpToDate}
                        />
                        <ContextProvider.Provider
                            value={{
                                type: 'impervious',
                                setAreaResultsUpToDate,
                                setWsudReady,
                                ...mapInfo,
                            }}
                        >
                            <AreaForm areas={areas['impervious']} />
                        </ContextProvider.Provider>

                        <ContextProvider.Provider
                            value={{
                                type: 'pervious',
                                setAreaResultsUpToDate,
                                setWsudReady,
                                ...mapInfo,
                            }}
                        >
                            <AreaForm areas={areas['pervious']} />
                        </ContextProvider.Provider>
                        <SummarySection />
                        <OverallResults
                            targetReductions={targetReductions}
                            cityCouncil={cityCouncil}
                        />
                    </div>
                </div>
            </div>
        </QueryClientProvider>
    );
};

export default WSUDForm;
