// ResultsTable.jsx
import React from "react";
import styles from "./ResultsTable.module.css";

const ResultsTable = ({ title, header, results, greenOrRed }) => {
    return (
        <div className={styles.ResultsTable}>
            <h4>{title}</h4>
            <table className={styles.table}>
                <thead>
                    <tr>
                        {header.map((headerCell, index) => (
                            <td key={"header" + index}>{headerCell}</td>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {results.map((result, index) => (
                        <tr key={"row" + index}>
                            {result.map((resultCell, resultCellIndex) => (
                                <td
                                    key={"content" + resultCellIndex}
                                    style={{
                                        color:
                                            greenOrRed[
                                                index + "" + resultCellIndex
                                            ] === undefined
                                                ? "black"
                                                : greenOrRed[
                                                      index +
                                                          "" +
                                                          resultCellIndex
                                                  ]
                                                ? "green"
                                                : "red",
                                    }}
                                >
                                    {resultCell}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ResultsTable;
