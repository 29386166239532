import React from 'react';
import { StyleSheet, View, Text, Image, Link } from '@react-pdf/renderer';
import { stringOrNumber } from '../../auditorUtils';

function OsdDeviceTemplate({ nodeInfo, image, title, documentLinks }) {
    const styles = StyleSheet.create({
        title: {
            fontSize: 14,
            textAlign: 'center',
            marginBottom: '3%',
        },
        image: {
            width: '100%',
        },
        link: {
            textAlign: 'center',
            fontSize: 9,
            marginBottom: '2vh',
            marginTop: '2vh',
        },
        superscript: {
            fontSize: 5,
            lineHeight: 5,
        },
        // TABLE
        table: {
            padding: 5,
        },
        tableTitle: {
            width: '100%',
            textAlign: 'center',
            fontSize: 14,
            marginBottom: '3vh',
        },
        tableRow: {
            flexDirection: 'row',
        },
        tableCol: {
            flex: 1,
            textAlign: 'center',
            fontSize: 8,
            border: 1,
            padding: 5,
        },
    });

    return (
        <>
            <Text style={styles.tableTitle}>Specifications and Typical Drawings - {title}</Text>
            {image && <Image style={styles.image} src={image} />}
            {documentLinks
                ? documentLinks.map((link, index) => {
                      return (
                          <Link
                              key={link['manufacture_device_document_link']}
                              src={link['manufacture_device_document_link']}
                              style={styles.link}
                          >
                              {link['document_name'] ? link['document_name'] : `document ${index}`}
                          </Link>
                      );
                  })
                : null}
            <View style={styles.table}>
                <View style={styles.tableRow}>
                    <Text
                        style={{
                            flex: 1,
                            textAlign: 'center',
                            fontSize: '9',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            color: 'red',
                            paddingTop: '20px',
                        }}
                    >
                        {'  '}Device Name
                    </Text>

                    <Text
                        style={{
                            flex: 1,
                            textAlign: 'center',
                            fontSize: '9',
                            borderWidth: '1px 0 0 1px',
                            borderStyle: 'solid',
                            color: 'red',
                            paddingTop: '20px',
                            paddingBottom: '20px',
                        }}
                    >
                        Surface Area (m<Text style={styles.superscript}>2</Text>)
                    </Text>
                    <Text
                        style={{
                            flex: 1,
                            textAlign: 'center',
                            fontSize: '9',
                            borderWidth: '1px 0 0 1px',
                            borderStyle: 'solid',
                            color: 'red',
                            paddingTop: '20px',
                            paddingBottom: '20px',
                        }}
                    >
                        Extended Detention Depth (m)
                    </Text>
                    <Text
                        style={{
                            flex: 1,
                            textAlign: 'center',
                            fontSize: '9',
                            borderWidth: '1px 0 0 1px',
                            borderStyle: 'solid',
                            color: 'red',
                            paddingTop: '20px',
                            paddingBottom: '20px',
                        }}
                    >
                        Detention Volume (m<Text style={styles.superscript}>3</Text>)
                    </Text>
                    <Text
                        style={{
                            flex: 1,
                            textAlign: 'center',
                            fontSize: '9',
                            borderWidth: '1px 0 0 1px',
                            borderStyle: 'solid',
                            color: 'red',
                            paddingTop: '20px',
                            paddingBottom: '20px',
                        }}
                    >
                        Overflow Weir Width (metres)
                    </Text>
                    <Text
                        style={{
                            flex: 1,
                            textAlign: 'center',
                            fontSize: '9',
                            borderWidth: '1px 0 0 1px',
                            borderStyle: 'solid',
                            color: 'red',
                            paddingTop: '20px',
                            paddingBottom: '20px',
                        }}
                    >
                        Equivalent Pipe Diameter (mm)
                    </Text>
                    <Text
                        style={{
                            flex: 1,
                            textAlign: 'center',
                            fontSize: '9',
                            color: 'red',
                            paddingTop: '6px',
                            borderWidth: '1px 0 0 1px',
                            borderStyle: 'solid',
                        }}
                    >
                        Exfiltration Rate(mm/hr)
                    </Text>
                </View>
                {nodeInfo.map((node, key) => {
                    return (
                        <View style={styles.tableRow} key={key}>
                            <Text style={styles.tableCol}>
                                {node['Node Name'] ? node['Node Name'] : 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>
                                {node[
                                    'Storage and Infiltration Properties - Surface Area (square metres)'
                                ]
                                    ? stringOrNumber(
                                          node[
                                              'Storage and Infiltration Properties - Surface Area (square metres)'
                                          ],
                                      )
                                    : 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>
                                {node[
                                    'Storage and Infiltration Properties - Extended Detention Depth (metres)'
                                ]
                                    ? stringOrNumber(
                                          node[
                                              'Storage and Infiltration Properties - Extended Detention Depth (metres)'
                                          ],
                                      )
                                    : 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>
                                {node[
                                    'Storage and Infiltration Properties - Extended Detention Depth (metres)'
                                ] &&
                                node[
                                    'Storage and Infiltration Properties - Surface Area (square metres)'
                                ]
                                    ? stringOrNumber(
                                          node[
                                              'Storage and Infiltration Properties - Surface Area (square metres)'
                                          ],
                                      ) *
                                      stringOrNumber(
                                          node[
                                              'Storage and Infiltration Properties - Extended Detention Depth (metres)'
                                          ],
                                      )
                                    : 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>
                                {node['Outlet Properties - Overflow Weir Width (metres)']
                                    ? stringOrNumber(
                                          node['Outlet Properties - Overflow Weir Width (metres)'],
                                      )
                                    : 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>
                                {node['Outlet Properties - Equivalent Pipe Diameter (mm)']
                                    ? stringOrNumber(
                                          node['Outlet Properties - Equivalent Pipe Diameter (mm)'],
                                      )
                                    : 'N/A'}
                            </Text>
                            <Text style={styles.tableCol}>
                                {node[
                                    'Storage and Infiltration Properties - Exfiltration Rate (mm/hr)'
                                ] !== undefined &&
                                node[
                                    'Storage and Infiltration Properties - Exfiltration Rate (mm/hr)'
                                ] !== null
                                    ? stringOrNumber(
                                          +node[
                                              'Storage and Infiltration Properties - Exfiltration Rate (mm/hr)'
                                          ],
                                      )
                                    : 'N/A'}
                            </Text>
                        </View>
                    );
                })}
            </View>
        </>
    );
}

export default OsdDeviceTemplate;
