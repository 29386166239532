import axios from "axios";

export const convertMxProjToMsf = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    try {
        const response = await axios.post('https://testing.stormupdated.com.au/mxproj2msf', formData);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};