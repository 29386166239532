import React from 'react';
import { StyleSheet, Document, pdf, Page, View, Text, Image } from '@react-pdf/renderer';
// components
import BasicInformation from '../BasicInformation';
import ModifiedRationalReportTemplate from './ModifiedRationalReportTemplate';
import Table from '../Table';
import ReportFooter from '../ReportFooter';
import ReportHeader from '../ReportHeader';
import { getImageUrlFromS3 } from '../../api/s3';

const getModifiedRationalReport = (
    data,
    manufactureImageLinks,
    manufactureDocuments,
    imageSource,
    stormDurations,
) => {
    const defaultStyles = {
        borderStyle: '1px solid #658cbb',
        textAlignment: 'center',
        headerBorder: true,
        fontSize: 9,
        headerFontSize: 9,
        headerTextAlign: 'center',
        tableWidth: '100%',
    };
    const createStyles = (newStyles = defaultStyles) => {
        return {
            section: { marginTop: 15 },
            table: {
                display: 'table',
                width: newStyles.tableWidth,
                border: newStyles.borderStyle,
                borderBottom: 0,
                borderRight: 0,
                borderLeft: 0,
                borderTop: newStyles.headerBorder ? newStyles.borderStyle : 0,
            },

            tableRow: {
                flexDirection: 'row',
                borderBottom: newStyles.borderStyle,
            },

            headerCell: {
                fontSize: newStyles.headerFontSize,
                textAlign: newStyles.headerTextAlign,
                borderRight: newStyles.headerBorder ? newStyles.borderStyle : 0,
                borderLeft: newStyles.headerBorder ? newStyles.borderStyle : 0,
                height: '100%',
                padding: 5,
            },

            tableCell: {
                fontSize: newStyles.fontSize,
                textAlign: newStyles.textAlignment,
                borderRight: newStyles.borderStyle,
                borderLeft: newStyles.borderStyle,
                height: '100%',
                padding: 5,
            },
        };
    };

    const styles = StyleSheet.create({
        page: {
            paddingTop: 30,
            paddingBottom: 10,
            paddingHorizontal: 35,
        },
        imageContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
        },
        image: {
            paddingTop: 30,
            height: 350,
            width: 400,
        },
        mainTitle: {
            fontSize: 18,
            fontweight: 700,
            textAlign: 'center',
            marginTop: 5,
            marginBottom: 20,
        },
    });

    return (
        <Document>
            <BasicInformation
                reportHeaderUrl={data.reportHeaderIconUrl}
                address={{
                    suburb: data.suburb,
                    state: data.state,
                    postcode: data.postcode,
                    council: data.council,
                    address: data.address,
                    coordinate: data.coordinate,
                }}
                basicInfo={{
                    developmentType: data.developmentType,
                    permit: data.planningPermitNo,
                    projectName: 'N/A',
                    assessor: data.assessorName,
                    email: data.assessorEmail,
                }}
            />

            {/* OSD Summary */}
            <ModifiedRationalReportTemplate
                data={data}
                imageSource={imageSource}
                manufactureImageLinks={manufactureImageLinks}
                manufactureDocuments={manufactureDocuments}
            />

            {/* Storm Duration Chart */}
            <Page style={styles.page} size="A4">
                <View>
                    <ReportHeader reportHeaderUrl={data.reportHeaderIconUrl} />
                </View>

                <View>
                    <Text style={styles.mainTitle}>
                        Range of Durations used to Determine Max Volume Required
                    </Text>
                </View>
                <View style={styles.imageContainer}>
                    <Image src={data.osd.tDurChart} style={styles.image} />
                </View>
                {/* Footer */}
                <ReportFooter />
            </Page>
            {/* Storm Duration Table */}
            {stormDurations.map((arrayItem) => {
                return (
                    <Page key={arrayItem[0].t_dur} style={styles.page} size="A4" wrap={false}>
                        <View>
                            <ReportHeader reportHeaderUrl={data.reportHeaderIconUrl} />
                        </View>

                        <View>
                            <Text style={styles.mainTitle}>
                                Range of Durations Calculated {'\n'}to Determine Critical Duration
                            </Text>
                        </View>
                        <Table
                            columnNames={[
                                'Td, min',
                                'I, mm/hr',
                                'Qpeak, I/s',
                                'Volume Required, (m3)',
                            ]}
                            tableData={arrayItem.map((item) => {
                                const intenSityTDur = +item.intensity_tdur;
                                const qPostTN = +item.q_post_tn;
                                const vReqN = +item.Vreq_n;
                                if (+item.t_dur > 0)
                                    return [
                                        item.t_dur,
                                        intenSityTDur.toFixed(4),
                                        qPostTN.toFixed(4),
                                        vReqN.toFixed(4),
                                    ];
                                else return null;
                            })}
                            cellWidths={['25%', '25%', '25%', '25%']}
                            tableStyles={createStyles()}
                        />
                        {/* Footer */}
                        <ReportFooter />
                    </Page>
                );
            })}
        </Document>
    );
};

// main function
export const generateModifiedRationalReport = async (
    data,
    manufactureImageLinks,
    manufactureDocuments,
) => {
    // Get report image from S3
    const reportImageurl = await getImageUrlFromS3(
        'osd-report-images',
        'modified-rational-storage-volume-diagram.png',
    )
        .then((res) => res)
        .catch((err) => console.error('Fetching image from S3 failed!', err));

    const response = await fetch(reportImageurl, {
        headers: {
            'Access-Control-Allow-Origin': 'https://www.stormupdated.com.au',
            Origin: 'https://www.stormupdated.com.au',
        },
    });
    const blob = await response.blob();
    const imageSource = URL.createObjectURL(blob);

    // Seperate the Storm Durations into a few pages
    const filteredVreqNData = data.osdResult.vreq_n_data.filter(function (item) {
        return +item.t_dur > 0;
    });
    const stormDurations = [];
    const pageSize = 20;
    for (let i = 0; i < filteredVreqNData.length; i += pageSize)
        stormDurations.push(filteredVreqNData.slice(i, i + pageSize));

    try {
        const reportBlob = await pdf(
            getModifiedRationalReport(
                data,
                manufactureImageLinks,
                manufactureDocuments,
                imageSource,
                stormDurations,
            ),
        ).toBlob();
        return reportBlob;
    } catch (err) {
        console.error(err);
    }
};
